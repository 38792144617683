import { defaultApiInstance } from '@/compositions/api';

export function getRatingPartners() {
  return defaultApiInstance.get(`v1/partnerRating`);
}

export function getCommercialRating() {
  return defaultApiInstance.get(`v1/commercialRating`);
}

export function getSlotGrade() {
  return defaultApiInstance.get(`v1/slotGrade`);
}

export function createRatingPartner(data) {
  return defaultApiInstance.post(`v1/partnerRating/create`, JSON.stringify(data));
}

export function createCommercialRating(data) {
  return defaultApiInstance.post(`v1/commercialRating/create`, JSON.stringify(data));
}

export function createSlotGrade(data) {
  return defaultApiInstance.post(`v1/slotGrade/create`, JSON.stringify(data));
}

export function deactivateRatingPartner(id) {
  return defaultApiInstance.patch(`v1/partnerRating/${id}/deactivate`, JSON.stringify(id));
}

export function deactivateCommercialRating(id) {
  return defaultApiInstance.patch(`v1/commercialRating/${id}/deactivate`, JSON.stringify(id));
}

export function deactivateSlotGrade(id) {
  return defaultApiInstance.patch(`v1/slotGrade/${id}/deactivate`, JSON.stringify(id));
}

export function activateRatingPartner(id) {
  return defaultApiInstance.patch(`v1/partnerRating/${id}/activate`, JSON.stringify(id));
}

export function activateCommercialRating(id) {
  return defaultApiInstance.patch(`v1/commercialRating/${id}/activate`, JSON.stringify(id));
}

export function activateSlotGrade(id) {
  return defaultApiInstance.patch(`v1/slotGrade/${id}/activate`, JSON.stringify(id));
}

export function editRatingPartner(id, data) {
  return defaultApiInstance.put(`v1/partnerRating/${id}/update`, JSON.stringify(data));
}

export function editCommercialRating(id, data) {
  return defaultApiInstance.put(`v1/commercialRating/${id}/update`, JSON.stringify(data));
}

export function editSlotGrade(id, data) {
  return defaultApiInstance.put(`v1/slotGrade/${id}/update`, JSON.stringify(data));
}

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 10.5C6 9.11929 7.11929 8 8.5 8C9.88071 8 11 9.11929 11 10.5C11 11.2601 10.6608 11.941 10.1254 12.3995C11.2399 12.985 12 14.1537 12 15.5C12 15.7761 11.7761 16 11.5 16C11.2239 16 11 15.7761 11 15.5C11 14.1193 9.88071 13 8.5 13C7.11929 13 6 14.1193 6 15.5C6 15.7761 5.77614 16 5.5 16C5.22386 16 5 15.7761 5 15.5C5 14.1537 5.76011 12.985 6.87457 12.3995C6.33924 11.941 6 11.2601 6 10.5ZM8.5 12C9.32843 12 10 11.3284 10 10.5C10 9.67157 9.32843 9 8.5 9C7.67157 9 7 9.67157 7 10.5C7 11.3284 7.67157 12 8.5 12Z"
      fill="white"
    />
    <path
      d="M13.5 10C13.5 9.72386 13.7239 9.5 14 9.5H18C18.2761 9.5 18.5 9.72386 18.5 10C18.5 10.2761 18.2761 10.5 18 10.5H14C13.7239 10.5 13.5 10.2761 13.5 10Z"
      fill="white"
    />
    <path
      d="M15 13.5C14.7239 13.5 14.5 13.7239 14.5 14C14.5 14.2761 14.7239 14.5 15 14.5H18C18.2761 14.5 18.5 14.2761 18.5 14C18.5 13.7239 18.2761 13.5 18 13.5H15Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.5 6C1.5 4.61929 2.61929 3.5 4 3.5H20C21.3807 3.5 22.5 4.61929 22.5 6V18C22.5 19.3807 21.3807 20.5 20 20.5H4C2.61929 20.5 1.5 19.3807 1.5 18V6ZM4 4.5C3.17157 4.5 2.5 5.17157 2.5 6V18C2.5 18.8284 3.17157 19.5 4 19.5H20C20.8284 19.5 21.5 18.8284 21.5 18V6C21.5 5.17157 20.8284 4.5 20 4.5H4Z"
      fill="white"
    />
  </svg>
</template>

import { getTimeMatrix } from '@/methods/timeExpensesMatrix';
import { createTimeMatrix } from '@/methods/timeExpensesMatrix';
import { updateTimeMatrix } from '@/methods/timeExpensesMatrix';
import { deleteTimeMatrix } from '@/methods/timeExpensesMatrix';
import { ElNotification } from 'element-plus';
import { getErrorMessage, getDenyPrivilegeObject } from '@/compositions/helpers/helpers';

const state = {
  timeMatrixlist: [],
};

const getters = {
  getMatrixList(state) {
    return state.timeMatrixlist;
  },
};

const mutations = {
  UPDATE_MATRIX(state, data) {
    state.timeMatrixlist = data;
  },
};

const actions = {
  getTimeMatrix({ commit, state }, page) {
    getTimeMatrix(page)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_MATRIX', response.data.data);
          commit('privilege/UPDATE_TIME_MATRIX_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_TIME_MATRIX_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  createTimeMatrix({ commit, state }, params) {
    createTimeMatrix(params.data)
      .then((res) => {
        if ('success' in res.data && res.data.success) {
          ElNotification.success({
            message: res.data.message,
            duration: 2000,
          });
          getTimeMatrix(params.payload)
            .then((response) => {
              if ('success' in response.data && response.data.success) {
                commit('UPDATE_MATRIX', response.data.data);
                commit('privilege/UPDATE_TIME_MATRIX_PRIVILEGE', response.data.privilege, { root: true });
              }
            })
            .catch(() => {
              commit('privilege/UPDATE_TIME_MATRIX_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
            });
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  updateTimeMatrix({ commit, state }, params) {
    updateTimeMatrix(params)
      .then((res) => {
        if ('success' in res.data && res.data.success) {
          ElNotification.success({
            message: res.data.message,
            duration: 2000,
          });
          getTimeMatrix(params.payload)
            .then((response) => {
              if ('success' in response.data && response.data.success) {
                commit('UPDATE_MATRIX', response.data.data);
                commit('privilege/UPDATE_TIME_MATRIX_PRIVILEGE', response.data.privilege, { root: true });
              }
            })
            .catch(() => {
              commit('privilege/UPDATE_TIME_MATRIX_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
            });
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  deleteTimeMatrix({ commit, state }, params) {
    deleteTimeMatrix(params.id)
      .then((res) => {
        if ('success' in res.data && res.data.success) {
          ElNotification.success({
            message: res.data.message,
            duration: 2000,
          });
          getTimeMatrix(params.data).then((response) => {
            if ('success' in response.data && response.data.success) {
              commit('UPDATE_MATRIX', response.data.data);
            }
          });
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import { defaultApiInstance } from '@/compositions/api';

export function getHoldsList({ ...payload }) {
  return defaultApiInstance.get(
    `v1/internalHolding/${payload.storageId}/storage?limit=${payload.payload.limit}&offset=${payload.payload.page ?? 1}`
  );
}

export function createHold(payload) {
  return defaultApiInstance.post(`v1/internalHolding`, payload);
}

export function createSingleHold(payload) {
  return defaultApiInstance.post(`v1/internalHolding/single`, payload);
}

export function takeLastHold(holdId) {
  return defaultApiInstance.get(`v1/internalHolding/direction/${holdId}/lastSlotDate`);
}

export function updateHold(payload) {
  return defaultApiInstance.patch(`v1/internalHolding/${payload.holdId}/update`, payload.endDate);
}

/* eslint-disable */
import {
  getCapacityWeekList,
  createCapacityWeekItem,
  getCapacityForecast,
  getCapacityBooking,
  updateCapacityWeekItem,
  updateCapacityShiftRestrictionCount,
  updateCapacityShiftZeroRoom,
  updateCapacityShiftRepackaging,
  updateCapacityTypeCooperationCount,
  updateCapacityTypeCooperationCountHand,
  getCapacityPlanImport,
  createCapacityPlanImport,
  updateCapacityPlanImport,
  deleteCapacityPlanImport,
  getCapacityShifts,
} from '@/methods/capacity';
import { ElNotification } from 'element-plus';
import { getDenyPrivilegeObject, getErrorMessage } from '@/compositions/helpers/helpers';

const state = {
  weekList: [],
  forecast: {},
  booking: [],
  planImport: {},
  capacityShifts: [],
  dataIsUpdated: {
    week: false,
    forecast: false,
    planImport: false,
  },
};

const getters = {
  getCapacityWeekList(state) {
    return state.weekList;
  },
  getCapacityForecast(state) {
    return state.forecast;
  },
  getCapacityBooking(state) {
    return state.booking;
  },
  getCapacityPlanImport(state) {
    return state.planImport;
  },
};

const mutations = {
  UPDATE_CAPACITY_SHIFTS(state, data) {
    state.capacityShifts = data;
  },
  UPDATE_CAPACITY_WEEK_LIST(state, { restrictionId, data }) {
    state.weekList[restrictionId] = data;
  },
  UPDATE_CAPACITY_FORECAST(state, data) {
    const groupedCapacityShifts = [];

    if (Object.values(data?.capacityShifts).length > 0) {
      data['capacityShiftsCount'] = data.capacityShifts.length;
      data['restrictionCountPerShift'] = Math.floor(data?.restrictionCount / data.capacityShifts.length);

      Object.values(data.capacityShifts).map((item) => {
        const date = item.date;
        const group = groupedCapacityShifts.find((group) => group.date === date);

        if (!group) {
          groupedCapacityShifts.push({
            date,
            weekDay: new Date(date).toLocaleString('ru', {
              weekday: 'long',
            }),
            items: [item],
          });
        } else {
          group.items.push(item);
        }
      });
    }

    data['capacityShifts'] = groupedCapacityShifts;

    state.forecast = data;
  },
  UPDATE_CAPACITY_BOOKING(state, data) {
    state.booking = data;
  },
  UPDATE_CAPACITY_PLAN_IMPORT(state, data) {
    state.planImport = data;
  },
  CAPACITY_WEEK_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated.week = data;
  },
  CAPACITY_FORECAST_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated.forecast = data;
  },
  CAPACITY_PLAN_IMPORT_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated.planImport = data;
  },
};

const actions = {
  getCapacityWeekList({ commit, state }, params) {
    getCapacityWeekList(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_CAPACITY_WEEK_LIST', { restrictionId: params.restrictionId, data: response.data.data });
          commit('privilege/UPDATE_SETTINGS_CAPACITY_WEEK_LIST_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_CAPACITY_WEEK_LIST_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },
  getCapacityShifts({ commit, state }, params) {
    getCapacityShifts(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_CAPACITY_SHIFTS', response.data.data.data);
      }
    });
  },
  createCapacityWeekItem({ commit, dispatch, state }, params) {
    createCapacityWeekItem(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('CAPACITY_WEEK_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  getCapacityForecast({ commit, state }, params) {
    getCapacityForecast(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_CAPACITY_FORECAST', response.data.data);
          commit('privilege/UPDATE_SETTINGS_CAPACITY_FORECAST_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_CAPACITY_FORECAST_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  getCapacityBooking({ commit, state }, params) {
    getCapacityBooking(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_CAPACITY_BOOKING', response.data.data);
          commit('privilege/UPDATE_SETTINGS_CAPACITY_BOOKING_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_CAPACITY_BOOKING_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  updateCapacityWeekItem({ commit, dispatch, state }, { weekId, data }) {
    updateCapacityWeekItem(weekId, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('CAPACITY_FORECAST_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  updateCapacityShiftRestrictionCount({ commit, dispatch, state }, { shiftId, data }) {
    updateCapacityShiftRestrictionCount(shiftId, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('CAPACITY_FORECAST_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  updateCapacityShiftZeroRoom({ commit, dispatch, state }, { shiftId, data }) {
    updateCapacityShiftZeroRoom(shiftId, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('CAPACITY_FORECAST_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  updateCapacityShiftRepackaging({ commit, dispatch, state }, { shiftId, data }) {
    updateCapacityShiftRepackaging(shiftId, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('CAPACITY_FORECAST_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  updateCapacityTypeCooperationCount({ commit, dispatch, state }, { typeCooperationId, data }) {
    updateCapacityTypeCooperationCount(typeCooperationId, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('CAPACITY_FORECAST_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  updateCapacityTypeCooperationCountHand({ commit, dispatch, state }, params) {
    updateCapacityTypeCooperationCountHand(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('CAPACITY_FORECAST_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  getCapacityPlanImport({ commit, state }, params) {
    getCapacityPlanImport(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_CAPACITY_PLAN_IMPORT', response.data.data);
          commit('privilege/UPDATE_SETTINGS_CAPACITY_PLAN_IMPORT_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_CAPACITY_PLAN_IMPORT_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  createCapacityPlanImport({ commit, dispatch, state }, params) {
    createCapacityPlanImport(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('CAPACITY_PLAN_IMPORT_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  updateCapacityPlanImport({ commit, dispatch, state }, { planImportId, data }) {
    updateCapacityPlanImport(planImportId, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('CAPACITY_PLAN_IMPORT_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  deleteCapacityPlanImport({ commit, dispatch, state }, params) {
    deleteCapacityPlanImport(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('CAPACITY_PLAN_IMPORT_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

/* eslint-disable */
import { getDirectionLastSlot } from '@/methods/direction';

const state = {
  lastSlot: {},
};

const getters = {
  getDirectionLastSlot(state) {
    return state.lastSlot;
  },
};

const mutations = {
  UPDATE_DIRECTION_LAST_SLOT(state, data) {
    state.lastSlot = data;
  },
};

const actions = {
  getDirectionLastSlot({ commit, state }, params) {
    getDirectionLastSlot(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_DIRECTION_LAST_SLOT', response.data.data);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

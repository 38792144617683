/* eslint-disable */
import { defaultApiInstance } from '@/compositions/api';

export function getBookingItemTags(params) {
  let queryString = '';

  if (params?.bookingId) {
    queryString += `?bookingId=${params.bookingId}`
  }

  return defaultApiInstance.get(`v1/tag${queryString}`);
}

export function linkTagToBooking(id, data) {
  return defaultApiInstance.patch(`v1/tag/${id}/link`, JSON.stringify(data));
}

export function unlinkTagFromBooking(id, bookingId) {
  return defaultApiInstance.delete(`v1/tag/${id}/unlink?bookingId=${bookingId}`);
}

/* eslint-disable */
import { defaultApiInstance } from '@/compositions/api';

export function getDirectionDocumentList(directionId) {
  return defaultApiInstance.get(`v1/direction/${directionId}/document`);
}

export function createDirectionDocument(directionId, data) {
  return defaultApiInstance.post(`v1/direction/${directionId}/document/create`, JSON.stringify(data));
}

export function updateDirectionDocument(directionId, documentId, data) {
  return defaultApiInstance.put(`v1/direction/${directionId}/document/${documentId}/update`, JSON.stringify(data));
}

export function deleteDirectionDocument(documentId) {
  return defaultApiInstance.delete(`v1/document/${documentId}/remove`);
}

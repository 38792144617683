<script>
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import { menu } from "@/compositions/blocks/menu";
import Building from '@/components/common/icons/Building.vue';
import Box from '@/components/common/icons/Box.vue';
import Calendar from '@/components/common/icons/Calendar.vue';
import Booking from '@/components/common/icons/Booking.vue';
import ExpandUp from '@/components/common/icons/ExpandUp.vue';
import Gate from '@/components/common/icons/Gate.vue';
import Route from '@/components/common/icons/Route.vue';
import Options from '@/components/common/icons/Options.vue';
import Warehouse from '@/components/common/icons/Warehouse.vue';
import Partners from '@/components/common/icons/Partners.vue';
import Communicate from '@/components/common/icons/Communicate.vue';
import CarRegistry from '@/components/common/icons/CarRegistry.vue';
import Changelog from '@/components/common/icons/Changelog.vue';

export default {
  components: {
    Building,
    Box,
    Calendar,
    Booking,
    ExpandUp,
    Gate,
    Route,
    Options,
    Warehouse,
    Partners,
    Communicate,
    CarRegistry,
    Changelog
  },
  setup() {
    const route = useRoute();
    const {
      toggleMenu,
      clickOnRoute,
      setStartRoute,
      routes,
      menuActive
    } = menu();

    onMounted(() => {
      setStartRoute(route.path);
    })

    return {
      toggleMenu,
      clickOnRoute,
      routes,
      menuActive
    };
  },
};
</script>

<template>
  <nav
    class="menu"
    :class="{ '--active': menuActive }"
  >
    <div
      class="menu__burger"
      :class="{ '--active' : menuActive }"
      @click="toggleMenu()"
    >
      <div class="menu__burger-icon">
        <div class="menu__burger-line menu__burger-line--first" />
        <div class="menu__burger-line menu__burger-line--second" />
        <div class="menu__burger-line menu__burger-line--third" />
      </div>
    </div>
    <ul class="menu__list">
      <li
        v-for="(route, routeIndex) in routes.filter(route => route.isAvailable)"
        :key="routeIndex"
        class="menu__item"
        :class="[ { '--active' : route.active }, { '--disabled' : route.isDisabled } ]"
        @click="clickOnRoute(route)"
      >
        <el-tooltip
          v-if="!menuActive"
          :content="route.name"
          placement="right"
          :hide-after="0"
          :transition="'none'"
        >
          <div class="menu__icon">
            <component :is="route.icon" />
          </div>
        </el-tooltip>
        <div
          v-if="menuActive"
          class="menu__icon"
        >
          <component :is="route.icon" />
        </div>
        {{ route.name }}
      </li>
    </ul>
  </nav>
</template>

<style src="./menu.scss" lang="scss" scoped></style>

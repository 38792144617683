/* eslint-disable */
import {
  getUserAvailableSections,
  login,
  ssoLogin,
  refreshToken,
  registration,
  registrationCheck,
  resetPassword,
} from '@/methods/auth';
import { defaultApiInstance } from '@/compositions/api';
import { ElNotification } from 'element-plus';
import { computed, watch } from 'vue';
import store from '@/store';
import router from '@/router';
import { getErrorMessage } from '@/compositions/helpers/helpers';

const state = {
  user: {
    username: localStorage.getItem('username') || null,
    userId: localStorage.getItem('userId') ? parseInt(localStorage.getItem('userId')) : null,
    token: localStorage.getItem('token') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    partnerId: localStorage.getItem('partnerId') ? parseInt(localStorage.getItem('partnerId')) : null,
    isPartner: localStorage.getItem('userIsPartner') ? JSON.parse(localStorage.getItem('userIsPartner')) : false,
    partners: localStorage.getItem('userPartners') ? JSON.parse(localStorage.getItem('userPartners')) : [],
    workGroup: localStorage.getItem('workGroup') || null,
  },
  ssoLoginProcess: false,
  registrationCheckStatus: false,
  resetPasswordLinkSent: false,
  availableSections: localStorage.getItem('sections') ? localStorage.getItem('sections').split(',') : [] || [],
  contentIsAvailable: true,
};

const getters = {
  getUser(state) {
    return state.user;
  },
};

const mutations = {
  UPDATE_USERNAME(state, data) {
    state.user.username = data;
    localStorage.setItem('username', data);
  },
  UPDATE_USER_ID(state, data) {
    state.user.userId = data;
    localStorage.setItem('userId', data);
  },
  UPDATE_TOKEN(state, data) {
    state.user.token = data;
    localStorage.setItem('token', data);
  },
  UPDATE_PARTNER_ID(state, data) {
    state.user.partnerId = data;
    localStorage.setItem('partnerId', data);
  },
  UPDATE_USER_PARTNERS(state, data) {
    state.user.partners = data;
    localStorage.setItem('userPartners', JSON.stringify(data));
  },
  UPDATE_REFRESH_TOKEN(state, data) {
    state.user.refreshToken = data;
    localStorage.setItem('refreshToken', data);
  },
  UPDATE_USER_IS_PARTNER(state, data) {
    state.user.isPartner = data;
    localStorage.setItem('userIsPartner', JSON.stringify(data));
  },
  UPDATE_USER_WORKGROUP(state, data) {
    state.user.workGroup = data;
    localStorage.setItem('workGroup', data);
  },
  UPDATE_SSO_LOGIN_PROCESS(state, data) {
    state.ssoLoginProcess = data;
  },
  UPDATE_REGISTRATION_CHECK_STATUS(state, data) {
    state.registrationCheckStatus = data;
  },
  UPDATE_RESET_PASSWORD_LINK_SENT(state, data) {
    state.resetPasswordLinkSent = data;
  },
  UPDATE_USER_AVAILABLE_SECTIONS(state, data) {
    state.availableSections = data;
    localStorage.setItem('sections', data);

    location.href = '/dashboard';
  },
  UPDATE_CONTENT_AVAILABILITY(state, data) {
    state.contentIsAvailable = data;
  },
  LOGOUT(state) {
    state.user.username = null;
    state.user.userId = null;
    state.user.token = null;
    state.user.refreshToken = null;
    state.user.partnerId = null;
    state.user.isPartner = false;
    state.user.workGroup = null;
    state.user.partners = [];
    state.availableSections = [];
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('username');
    localStorage.removeItem('userId');
    localStorage.removeItem('partnerId');
    localStorage.removeItem('sections');
    localStorage.removeItem('userIsPartner');
    localStorage.removeItem('userPartners');
    localStorage.removeItem('workGroup');
    localStorage.removeItem('phpSessId');
  },
};

const actions = {
  login({ commit, dispatch, state, rootGetters }, params) {
    login(params)
      .then(async (response) => {
        if ('success' in response.data && response.data.success) {
          const result = response.data.data,
            currentPartner = computed(() => store.state.partner.currentPartner);

          await commit('UPDATE_USERNAME', result.username);
          await commit('UPDATE_USER_ID', result.userId);
          await commit('UPDATE_TOKEN', result.token);
          await commit('UPDATE_USER_PARTNERS', result.partners);
          await commit('UPDATE_REFRESH_TOKEN', result.refreshToken);
          await commit('UPDATE_USER_IS_PARTNER', result.isPartner);
          await commit('UPDATE_USER_WORKGROUP', result.workGroup?.code);

          if (state.user.partnerId === null && result.partners && result.partners.length) {
            await commit('UPDATE_PARTNER_ID', result.partners[0].id);
          }

          defaultApiInstance.interceptors.request.use(function (config) {
            config.headers['X-AUTH-TOKEN'] = `${result.token}`;
            return config;
          });

          if (result.isPartner && rootGetters['auth/getUser'].partnerId) {
            await dispatch('partner/getCurrentPartner', rootGetters['auth/getUser'].partnerId, { root: true });

            watch(
              () => currentPartner.value,
              async (value) => {
                if (value) {
                  let partnerStorageIds = [];

                if (value?.storages && value?.storages.length) {
                  value.storages.forEach((storage) => {
                    partnerStorageIds.push(storage.id);
                  });
                }

                if (
                  !rootGetters['storage/getCurrentStorageItemId'] ||
                  (rootGetters['storage/getCurrentStorageItemId'] &&
                    !partnerStorageIds.includes(rootGetters['storage/getCurrentStorageItemId']))
                ) {
                  await commit('storage/UPDATE_CURRENT_STORAGE_ITEM_ID', value?.mainStorage?.id, { root: true });
                }
                  await dispatch('getUserAvailableSections');
                }
              }
            );
          }

          if (!result.isPartner) {
            await dispatch('getUserAvailableSections');
          }
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  ssoLogin({ commit, dispatch, state }, params) {
    ssoLogin(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          const result = response.data.data;

          commit('UPDATE_USERNAME', result.username);
          commit('UPDATE_USER_ID', result.userId);
          commit('UPDATE_TOKEN', result.token);
          commit('UPDATE_USER_PARTNERS', result.partners);
          commit('UPDATE_REFRESH_TOKEN', result.refreshToken);
          commit('UPDATE_USER_IS_PARTNER', result.isPartner);
          commit('UPDATE_USER_WORKGROUP', result.workGroup?.code);

          if (state.user.partnerId === null && result.partners && result.partners.length) {
            commit('UPDATE_PARTNER_ID', result.partners[0].id);
          }

          defaultApiInstance.interceptors.request.use(function (config) {
            config.headers['X-AUTH-TOKEN'] = `${result.token}`;
            return config;
          });

          dispatch('getUserAvailableSections');
        }
      })
      .catch((error) => {
        commit('UPDATE_SSO_LOGIN_PROCESS', false);
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  refreshToken({ commit, state }, params) {
    refreshToken(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        const result = response.data.data;

        commit('UPDATE_TOKEN', result.token);
        commit('UPDATE_REFRESH_TOKEN', result.refreshToken);
      }
    });
  },

  registrationCheck({ commit, state }, params) {
    registrationCheck(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          const result = response.data;

          if (result?.success) {
            commit('UPDATE_REGISTRATION_CHECK_STATUS', true);
          }
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });

        router.replace({
          name: 'resetPassword',
          params: {
            hash: null,
          },
        });
      });
  },

  registration({ commit, dispatch, state }, { hash, data }) {
    const userPassword = data?.password;

    registration(hash, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          const userName = response.data?.data?.username;
          dispatch('login', {
            username: userName,
            password: userPassword,
          });
          router.replace({
            name: 'main',
          });
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  resetPassword({ commit, dispatch, state }, params) {
    resetPassword(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          const result = response.data;

          if (result?.success) {
            commit('UPDATE_RESET_PASSWORD_LINK_SENT', true);
          }
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  getUserAvailableSections({ commit, state }, params) {
    getUserAvailableSections(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          const result = response.data.data;
          commit('UPDATE_USER_AVAILABLE_SECTIONS', result);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  logout({ commit }) {
    commit('LOGOUT');
    delete defaultApiInstance.defaults.headers['X-AUTH-TOKEN'];

    location.reload();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

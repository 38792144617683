/* eslint-disable */
import { defaultApiInstance, loginApiInstance } from '@/compositions/api';

export function login(data) {
  return loginApiInstance.post(`v1/login`, JSON.stringify(data))
}

export function ssoLogin(data) {
  return loginApiInstance.post(`v1/sso/login`, JSON.stringify(data))
}

export function refreshToken(data) {
  return loginApiInstance.post(`v1/login/refresh`, JSON.stringify(data))
}

export function registrationCheck(hash) {
  return loginApiInstance.get(`v1/registration/${hash}/check`)
}

export function registration(hash, data) {
  return loginApiInstance.post(`v1/registration/${hash}`, JSON.stringify(data))
}

export function resetPassword(data) {
  return loginApiInstance.post(`v1/resetPassword`, JSON.stringify(data))
}

export function getUserAvailableSections() {
  return defaultApiInstance.get(`v1/section`)
}

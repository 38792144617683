import { defaultApiInstance } from '@/compositions/api';

export function getTimeMatrix(payload) {
  return defaultApiInstance.get(
    `v1/timeMatrix/?offset=${payload.page ?? 1}&limit=${payload.limit}&storageId=${payload.storageId}`
  );
}

export function createTimeMatrix(params) {
  return defaultApiInstance.post(`v1/timeMatrix/`, params);
}

export function updateTimeMatrix(params) {
  return defaultApiInstance.put(`v1/timeMatrix/${params.id}`, params.data);
}
export function deleteTimeMatrix(id) {
  return defaultApiInstance.delete(`v1/timeMatrix/${id}`);
}

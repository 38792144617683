/* eslint-disable */
import { defaultApiInstance } from '@/compositions/api';

export function getBookingItemComments(params) {
  let queryString = '';

  if (params?.bookingId) {
    queryString += `?bookingId=${params.bookingId}`
  }

  return defaultApiInstance.get(`v1/comment${queryString}`);
}

export function createBookingItemComment(data) {
  return defaultApiInstance.post(`v1/comment`, JSON.stringify(data));
}

export function updateBookingItemComment(id, data) {
  return defaultApiInstance.patch(`v1/comment/${id}`, JSON.stringify(data));
}

export function deleteBookingItemComment(id) {
  return defaultApiInstance.delete(`v1/comment/${id}`);
}

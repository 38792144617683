<template>
  <!-- eslint-disable -->
  <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M44 14L24 4L4 14V34L24 44L44 34V14Z" stroke="white" stroke-width="2" stroke-linejoin="round"/>
    <path d="M4 14L24 24" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24 44V24" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M44 14L24 24" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M34 9L14 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

/* eslint-disable */
import { defaultApiInstance } from '@/compositions/api';

export function getBookingList(params) {
  return defaultApiInstance.get(`v1/booking`, { params });
}

export function getBookingItem(id) {
  return defaultApiInstance.get(`v1/booking/${id}`);
}

export function getBookingItemHistory(params) {
  return defaultApiInstance.get(`v1/booking/${params?.itemId}/changeLog`, {
    params: params?.query,
  });
}

export function getBookingAvailableSlots(params) {
  return defaultApiInstance.get(`v1/slot/availableSlots`, { params });
}

export function getBookingAvailableSlotsSearch(params) {
  return defaultApiInstance.get(`v1/slot/search`, {
    params: {
      limit: params.limit,
      offset: params.offset,
      directionId: params.directionId,
      storageId: params.storageId,
      fromAt: params.fromAt,
      toAt: params.toAt,
    },
  });
}

export function getBookingAvailableSlotsInterrogation(params) {
  return defaultApiInstance.get(`v1/slot/availableSlots/interrogation`, { params });
}

export function createSlotSubscription(data) {
  return defaultApiInstance.post(`v1/slot/subscription`, JSON.stringify(data));
}

export function getSlotSubscription(id) {
  return defaultApiInstance.get(`v1/slot/subscription/${id}`);
}

export function getSlotSubscriptionAvailableDates(params) {
  return defaultApiInstance.get(`v1/slot/subscription/available/dates`, { params });
}

export function getBookingExport(params) {
  return defaultApiInstance.get(`v1/booking/export`, { params });
}

export function checkBookingSupplyItemSlots(data) {
  return defaultApiInstance.post(`v1/booking/supply/check`, JSON.stringify(data));
}

export function checkBookingReturnItemSlots(data) {
  return defaultApiInstance.post(`v1/booking/return/check`, JSON.stringify(data));
}

export function checkBookingWithLamodaCarSupplyItemSlots(data) {
  return defaultApiInstance.post(`v1/booking/supplyWithLamodaCar/check`, JSON.stringify(data));
}

export function checkBookingWithLamodaCarReturnItemSlots(data) {
  return defaultApiInstance.post(`v1/booking/returnWithLamodaCar/check`, JSON.stringify(data));
}

export function getBookingItemGates(id) {
  return defaultApiInstance.get(`v1/booking/${id}/gates`);
}

export function getBookingItemTransitionStatus(id) {
  return defaultApiInstance.get(`v1/booking/${id}/transitionStatus`);
}

export function getBookingItemShipmentsLeftData(id) {
  return defaultApiInstance.get(`v1/booking/${id}/shipmentsLeft`);
}

export function getBookingItemShipmentsRightData(id) {
  return defaultApiInstance.get(`v1/booking/${id}/shipmentsRight`);
}

export function getBookingItemReturnsLeftData(id) {
  return defaultApiInstance.get(`v1/booking/${id}/returnsLeft`);
}

export function getBookingItemInboundLeftData(id) {
  return defaultApiInstance.get(`v1/booking/${id}/inboundLeft`);
}

export function getBookingItemOutboundLeftData(id) {
  return defaultApiInstance.get(`v1/booking/${id}/outboundLeft`);
}

export function getBookingPeriod() {
  return defaultApiInstance.get(`v1/booking/periods`);
}

export function createBookingShipmentItem(data) {
  return defaultApiInstance.post(`v1/booking/supply`, JSON.stringify(data));
}

export function createBookingShipmentItemNoLimits(data) {
  return defaultApiInstance.post(`v1/booking/supply/no/limits`, JSON.stringify(data));
}

export function createBookingReturnItem(data) {
  return defaultApiInstance.post(`v1/booking/return`, JSON.stringify(data));
}

export function createBookingReturnItemNoLimits(data) {
  return defaultApiInstance.post(`v1/booking/return/no/limits`, JSON.stringify(data));
}

export function createBookingInboundItem(data) {
  return defaultApiInstance.post(`v1/booking/inbound`, JSON.stringify(data));
}

export function createBookingInboundItemNoLimits(data) {
  return defaultApiInstance.post(`v1/booking/inbound/no/limits`, JSON.stringify(data));
}

export function createBookingOutboundItem(data) {
  return defaultApiInstance.post(`v1/booking/outbound`, JSON.stringify(data));
}

export function createBookingOutboundItemNoLimits(data) {
  return defaultApiInstance.post(`v1/booking/outbound/no/limits`, JSON.stringify(data));
}

export function createBookingLamodaCar(data) {
  return defaultApiInstance.post(`v1/booking/supplyWithLamodaCar`, JSON.stringify(data));
}

export function getTimeBookingSupplyWithLamodaCar() {
  return defaultApiInstance.get(`v1/booking/when/supplyWithLamodaCar`);
}

export function createBookingReturnLamodaCar(data) {
  return defaultApiInstance.post(`v1/booking/returnWithLamodaCar`, JSON.stringify(data));
}

export function getTimeBookingReturnWithLamodaCar() {
  return defaultApiInstance.get(`v1/booking/when/returnWithLamodaCar`);
}

export function updateBookingShipmentItem(id, data) {
  return defaultApiInstance.put(`v1/booking/${id}/supply`, JSON.stringify(data));
}

export function updateBookingShipmentInterrogationItem(id, data) {
  return defaultApiInstance.put(`v1/booking/interrogation/${id}/supply`, JSON.stringify(data));
}

export function updateBookingReturnItem(id, data) {
  return defaultApiInstance.put(`v1/booking/${id}/return`, JSON.stringify(data));
}

export function updateBookingReturnInterrogationItem(id, data) {
  return defaultApiInstance.put(`v1/booking/interrogation/${id}/return`, JSON.stringify(data));
}

export function updateBookingInboundItem(id, data) {
  return defaultApiInstance.put(`v1/booking/${id}/inbound`, JSON.stringify(data));
}

export function updateBookingOutboundItem(id, data) {
  return defaultApiInstance.put(`v1/booking/${id}/outbound`, JSON.stringify(data));
}

export function updateFactCorrection(id, data) {
  return defaultApiInstance.put(`/api/v1/booking/${id}/factCorrection`, JSON.stringify(data));
}

export function updateBookingSupplyWithLamodaCar({ id, data }) {
  return defaultApiInstance.put(`v1/booking/supplyWithLamodaCar/${id}`, JSON.stringify(data));
}

export function updateBookingReturnWithLamodaCar({ id, data }) {
  return defaultApiInstance.put(`v1/booking/returnWithLamodaCar/${id}`, JSON.stringify(data));
}

export function changeBookingStatusOnly(params) {
  return defaultApiInstance.patch(`v1/booking/${params.id}/statusOnly`, JSON.stringify(params.status));
}

export function changeBookingItemDriverReleasedStatus(id, data) {
  return defaultApiInstance.patch(`v1/booking/${id}/driverReleased`, JSON.stringify(data));
}

export function changeBookingItemDocumentStatus(id, documentId, data) {
  return defaultApiInstance.patch(`v1/booking/${id}/document/${documentId}/status`, JSON.stringify(data));
}

export function changeBookingItemDocumentComment(id, documentId, data) {
  return defaultApiInstance.patch(`v1/booking/${id}/document/${documentId}/comment`, JSON.stringify(data));
}

export function changeBookingItemStatusDocumentsAccepted(id, data) {
  return defaultApiInstance.patch(`v1/booking/${id}/documentsAccepted`, JSON.stringify(data));
}

export function changeBookingItemStatusGateCheckIn(id, data) {
  return defaultApiInstance.patch(`v1/booking/${id}/gateCheckIn`, JSON.stringify(data));
}

export function saveBookingItem(id, data) {
  return defaultApiInstance.patch(`v1/booking/${id}/shipmentsSave`, JSON.stringify(data));
}

export function changeBookingItemStatus(id, data) {
  return defaultApiInstance.patch(`v1/booking/${id}/statusOnly`, JSON.stringify(data));
}

export function changeBookingItemDriverArrivedStatus(id, data) {
  return defaultApiInstance.patch(`v1/booking/${id}/driverArrived`, JSON.stringify(data));
}

export function changeBookingItemCarProcessingStatus(id, data) {
  return defaultApiInstance.patch(`v1/booking/${id}/carProcessing`, JSON.stringify(data));
}

export function changeBookingItemProcessingDoneStatus(id, data) {
  return defaultApiInstance.patch(`v1/booking/${id}/processingDone`, JSON.stringify(data));
}

export function changeBookingItemCancelByManagerStatus(id, data) {
  return defaultApiInstance.patch(`v1/booking/${id}/cancelByManager`, JSON.stringify(data));
}

export function changeBookingItemCarParkingStatus(id, data) {
  return defaultApiInstance.patch(`v1/booking/${id}/carParking`, JSON.stringify(data));
}

export function getInterrogationBookingList(params) {
  return defaultApiInstance.get(`v1/booking/is/interrogation`, { params });
}

export function approveInterrogationBookingItem(id) {
  return defaultApiInstance.patch(`v1/booking/is/interrogation/${id}/approve/`);
}

export function cancelInterrogationBookingItem(id) {
  return defaultApiInstance.patch(`v1/booking/is/interrogation/${id}/cancel`);
}

export function cancelBookingItems(data) {
  return defaultApiInstance.delete(`v1/booking`, { data: JSON.stringify(data) });
}

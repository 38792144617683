import {
  setPartnersDirectionRestriction,
  getLimitedReturns,
  getBookingTimeRestriction,
  getRestrictions,
  setRestrictionByName,
  getTermLateReturn,
  setTermLateReturn,
  removeLateReturn,
  updateLateReturn,
} from '@/methods/partnerRestrictions';
import { ElNotification } from 'element-plus';
import { getDenyPrivilegeObject, getErrorMessage } from '@/compositions/helpers/helpers';

const state = {
  restrictionsList: [],
  termLateList: [],
};

const getters = {
  getRestrictions(state) {
    return state.restrictionsList;
  },
  getTermLate(state) {
    return state.termLateList;
  },
};

const mutations = {
  UPDATE_RESTRICTIONS_LIST(state, data) {
    state.restrictionsList = data;
  },
  UPDATE_TERM_LATE_LIST(state, data) {
    state.termLateList = data;
  },
};

const actions = {
  getRestrictions({ commit, state }) {
    getRestrictions()
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_RESTRICTIONS_LIST', response.data.data);
          commit('privilege/UPDATE_SETTINGS_PARTNER_RESTRICTIONS_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_PARTNER_RESTRICTIONS_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },
  getTermLateReturn({ commit, state }) {
    getTermLateReturn()
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_TERM_LATE_LIST', response.data.data);
          commit('privilege/UPDATE_SETTINGS_TERM_LATE_RETURN_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_TERM_LATE_RETURN_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  setPartnersDirectionRestriction({ commit, state }, payload) {
    setPartnersDirectionRestriction(payload)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  setTermLateReturn({ commit, dispatch, state }, payload) {
    setTermLateReturn(payload)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          dispatch('getTermLateReturn');
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  updateLateReturn({ commit, dispatch, state }, payload) {
    updateLateReturn(payload)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          dispatch('getTermLateReturn');
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  setRestrictionByName({ commit, state }, payload) {
    setRestrictionByName(payload)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  removeLateReturn({ commit, dispatch, state }, payload) {
    removeLateReturn(payload)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          dispatch('getTermLateReturn');
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import { computed, watch } from 'vue';
import { createWebHistory, createRouter } from 'vue-router';
import store from '@/store';

/* eslint-disable */
const Login = () => import('@/components/views/auth/Login');
const Registration = () => import('@/components/views/auth/Registration');
const ResetPassword = () => import('@/components/views/auth/ResetPassword');
const Dashboard = () => import('@/components/views/dashboard/Dashboard');
const Settings = () => import('@/components/views/settings/Settings');
const Storage = () => import('@/components/views/storage/Storage');
const WorkTime = () => import('@/components/views/storage/WorkTime');
const WorkTimeEdit = () => import('@/components/views/storage/WorkTimeEdit');
const CalendarPage = () => import('@/components/views/calendarPage/CalendarPage');
const RatingMatrix = () => import('@/components/views/ratingMatrix/RatingMatrix');
const Forecast = () => import('@/components/views/capacity/Forecast');
const CapacityBooking = () => import('@/components/views/capacity/Booking');
const CapacityPlanImport = () => import('@/components/views/capacity/PlanImport');
const DirectionDocuments = () => import('@/components/views/direction/Documents');
const Routes = () => import('@/components/views/routes/Routes');
const Gates = () => import('@/components/pages/gates/Gates');
const ShipmentAndReturn = () => import('@/components/views/shipmentAndReturn/ShipmentAndReturn');
const ReturnItem = () => import('@/components/views/shipmentAndReturn/parts/ReturnItem');
const BookingPage = () => import('@/components/views/booking/BookingPage');
const BookingItem = () => import('@/components/views/booking/BookingItem');
const BookingItemCreate = () => import('@/components/views/booking/BookingItemCreate');
const Incident = () => import('@/components/views/incident/Incident');
const IncidentItem = () => import('@/components/views/incident/IncidentItem');
const IncidentItemCreate = () => import('@/components/views/incident/IncidentItemCreate');
const ManagePartnerUsers = () => import('@/components/views/managePartnerUsers/ManagePartnerUsers');
const PartnersRegistry = () => import('@/components/pages/partnersRegistry/PartnersRegistry');
const EditPartner = () => import('@/components/pages/partnersRegistry/parts/EditPartner');
const CarRegistryPage = () => import('@/components/views/carRegistryPage/CarRegistryPage');
const ChangeLog = () => import('@/components/views/changeLog/ChangeLog');
const Ui = () => import('@/components/views/ui/Ui');
const PageNotFound = () => import('@/components/views/404/404');
const userAvailableSections = computed(() => store.state.auth.availableSections);
const user = computed(() => store.state.auth.user);
const currentPartner = computed(() => store.state.partner.currentPartner);
const currentStorageItemId = computed(() => store.state.storage.currentStorageItemId);
/* eslint-enable */

const isAuthorized = localStorage.hasOwnProperty('token');

const authGuard = (to, from, next) => {
  if (!isAuthorized) {
    next({ name: 'main' });
  } else {
    let toPath = to.path.split('/');

    if (!Object.values(userAvailableSections.value).includes(toPath[1]) || to.query?.forbidden) {
      to.meta.layout = 'Forbidden';
    }

    if (user.value.isPartner && user.value.partnerId) {
      store.commit('partner/UPDATE_CURRENT_PARTNER', {}, { root: true });
      store.dispatch('partner/getCurrentPartner', user.value.partnerId, { root: true });

      watch(
        () => currentPartner.value,
        async (value) => {
          if (Object.values(value).length > 0) {
            let partnerStorageIds = [];

            if (value?.storages && value?.storages.length) {
              value.storages.forEach((storage) => {
                partnerStorageIds.push(storage.id);
              });
            }

            if (
              !currentStorageItemId.value ||
              (currentStorageItemId.value && !partnerStorageIds.includes(currentStorageItemId.value))
            ) {
              await store.commit('storage/UPDATE_CURRENT_STORAGE_ITEM_ID', value.mainStorage?.id, { root: true });
              window.location.reload();
            }
          }
        }
      );
    }

    next();
  }
};

const routes = [
  {
    path: '/',
    name: 'main',
    meta: {
      layout: 'Auth',
    },
    component: Login,
  },
  {
    path: '/registration/:hash',
    name: 'registration',
    meta: {
      layout: 'Auth',
      title: 'Регистрация',
      description: 'Регистрация',
    },
    component: Registration,
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    meta: {
      layout: 'Auth',
      title: 'Восстановление пароля',
      description: 'Восстановление пароля',
    },
    component: ResetPassword,
  },
  {
    path: '/resetPassword/:hash',
    name: 'resetPasswordHash',
    meta: {
      layout: 'Auth',
      title: 'Восстановление пароля',
      description: 'Восстановление пароля',
    },
    component: ResetPassword,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Главная страница',
      description: 'Главная страница',
    },
    beforeEnter: authGuard,
    component: Dashboard,
  },
  {
    path: '/calendar',
    name: 'calendar',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Календарь',
      description: 'Календарь',
    },
    beforeEnter: authGuard,
    component: CalendarPage,
  },
  {
    path: '/settings/rating-matrix',
    name: 'ratingMatrix',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Матрица рейтинга партнеров',
      description: 'Матрица рейтинга партнеров',
    },
    beforeEnter: authGuard,
    component: RatingMatrix,
  },
  {
    path: '/settings',
    name: 'settings',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Настройки',
      description: 'Настройки',
    },
    beforeEnter: authGuard,
    component: Settings,
  },
  {
    path: '/storage',
    name: 'storage',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Настройки склада',
      description: 'Настройки склада',
    },
    beforeEnter: authGuard,
    component: Storage,
  },
  {
    path: '/storage/worktime',
    name: 'workTime',
    meta: {
      layout: 'Main',
      auth: true,
    },
    beforeEnter: authGuard,
    component: WorkTime,
  },
  {
    path: '/storage/worktime/edit',
    name: 'workTimeEdit',
    meta: {
      layout: 'Main',
      auth: true,
    },
    beforeEnter: authGuard,
    component: WorkTimeEdit,
  },
  {
    path: '/storage/restriction/:restrictionId/capacity/:capacityId/forecast',
    name: 'forecast',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Forecast',
      description: 'Forecast',
    },
    beforeEnter: authGuard,
    component: Forecast,
  },
  {
    path: '/storage/direction/:directionId/documents',
    name: 'directionDocuments',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Документы',
      description: 'Документы',
    },
    beforeEnter: authGuard,
    component: DirectionDocuments,
  },
  {
    path: '/storage/restriction/:restrictionId/capacity/:capacityId/booking',
    name: 'capacityBooking',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Сводка по броням партнеров',
      description: 'Сводка по броням партнеров',
    },
    beforeEnter: authGuard,
    component: CapacityBooking,
  },
  {
    path: '/storage/restriction/:restrictionId/capacity/:capacityId/plan-import',
    name: 'capacityPlanImport',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'План импорт',
      description: 'План импорт',
    },
    beforeEnter: authGuard,
    component: CapacityPlanImport,
  },
  {
    path: '/routes',
    name: 'routes',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Внутренние маршруты',
      description: 'Внутренние маршруты',
    },
    beforeEnter: authGuard,
    component: Routes,
  },
  {
    path: '/gate',
    name: 'gate',
    meta: {
      layout: 'Unwrapped',
      auth: true,
      title: 'Гейты',
      description: 'Гейты',
    },
    beforeEnter: authGuard,
    component: Gates,
  },
  {
    path: '/shipment-and-return',
    name: 'shipment-and-return',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Поставки и возвраты',
      description: 'Поставки и возвраты',
    },
    beforeEnter: authGuard,
    component: ShipmentAndReturn,
  },
  {
    path: '/shipment-and-return/return/create',
    name: 'return-create',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Создание возврата',
      description: 'Создание возврата',
    },
    beforeEnter: authGuard,
    component: ReturnItem,
  },
  {
    path: '/shipment-and-return/return/:returnId/:actionType',
    name: 'return-edit',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Редактирование возврата',
      description: 'Редактирование возврата',
    },
    beforeEnter: authGuard,
    component: ReturnItem,
  },
  {
    path: '/booking',
    name: 'booking',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Реестр броней',
      description: 'Реестр броней',
    },
    beforeEnter: authGuard,
    component: BookingPage,
  },
  {
    path: '/booking/create',
    name: 'booking-item-create',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Создание брони',
      description: 'Создание брони',
    },
    beforeEnter: authGuard,
    component: BookingItemCreate,
  },
  {
    path: '/booking/subscription/:slotSubscriptionId/create/',
    name: 'booking-item-subscription-create',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Создание брони из подписки',
      description: 'Создание брони из подписки',
    },
    beforeEnter: authGuard,
    component: BookingItemCreate,
  },
  {
    path: '/booking/partner-return/create',
    name: 'booking-item-partner-return-create',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Создание брони',
      description: 'Создание брони',
    },
    beforeEnter: authGuard,
    component: BookingItemCreate,
  },
  {
    path: '/booking/:bookingId',
    name: 'booking-item',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Работа с машиной',
      description: 'Работа с машиной',
    },
    beforeEnter: authGuard,
    component: BookingItem,
  },
  {
    path: '/booking/:bookingId/:actionType',
    name: 'booking-item-edit',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Редактирование брони',
      description: 'Редактирование брони',
    },
    beforeEnter: authGuard,
    component: BookingItemCreate,
  },
  {
    path: '/booking/:bookingId/incident',
    name: 'incident-item-create',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Создание обращения',
      description: 'Создание обращения',
    },
    beforeEnter: authGuard,
    component: IncidentItemCreate,
  },
  {
    path: '/incident',
    name: 'incident',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Реестр обращений',
      description: 'Реестр обращений',
    },
    beforeEnter: authGuard,
    component: Incident,
  },
  {
    path: '/incident/:incidentId',
    name: 'incident-item',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Просмотр обращения',
      description: 'Просмотр обращения',
    },
    beforeEnter: authGuard,
    component: IncidentItem,
  },
  {
    path: '/partners-registry',
    name: 'partners-registry',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Реестр партнеров',
      description: 'Реестр партнеров',
    },
    beforeEnter: authGuard,
    component: PartnersRegistry,
  },
  {
    path: '/partners-registry/:partnerId',
    name: 'partners-edit',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Настройки партнера',
      description: 'Настройки партнера',
    },
    beforeEnter: authGuard,
    component: EditPartner,
  },
  {
    path: '/manage-partner-users/:partnerId',
    name: 'manage-partner-users',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Управление пользователями',
      description: 'Управление пользователями',
    },
    beforeEnter: authGuard,
    component: ManagePartnerUsers,
  },
  {
    path: '/car-registry',
    name: 'car-registry',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'Заявки на машину и холдирования',
      description: 'Заявки на машину и холдирования',
    },
    beforeEnter: authGuard,
    component: CarRegistryPage,
  },
  {
    path: '/changelog',
    name: 'changelog',
    meta: {
      layout: 'Main',
      auth: true,
      title: 'История изменений в системе',
      description: 'История изменений в системе',
    },
    beforeEnter: authGuard,
    component: ChangeLog,
  },
  {
    path: '/ui',
    name: 'ui',
    meta: {
      layout: 'Main',
      auth: true,
    },
    component: Ui,
  },
  {
    path: '/404',
    name: '404',
    meta: {
      layout: 'Empty',
      auth: false,
      title: 'Страница не найдена',
      description: 'Страница не найдена',
    },
    component: PageNotFound,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

/* eslint-disable */
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { computed, onBeforeMount, ref, watch } from 'vue';

export function header() {
  /* eslint-enable */
  const router = useRouter();
  const username = computed(() => store.state.auth.user.username);
  const store = useStore();
  const route = useRoute();
  const storage = computed(() => store.state.storage.item);
  const storageList = computed(() => store.state.storage.list.filter((item) => item.active !== false));
  const authUserToken = computed(() => store.state.auth.user.token);
  const userPartnersList = computed(() => store.state.auth.user.partners);
  const userIsPartner = computed(() => store.state.auth.user.isPartner);
  const partnerId = computed(() => store.state.auth.user.partnerId);
  const currentPartner = computed(() => store.state.partner.currentPartner);

  const changeCurrentStorage = (id) => {
    store.commit('storage/UPDATE_CURRENT_STORAGE_ITEM', id);
    store.commit('storage/UPDATE_CURRENT_STORAGE_ITEM_ID', id);
    window.location.reload();
  };

  if (authUserToken.value) {
    store.dispatch('storage/getStorageList');
  }

  const changeCurrentUserPartner = async (id) => {
    store.commit('auth/UPDATE_PARTNER_ID', id);
    await store.dispatch('partner/getPartnerStorages', id);
    await store.dispatch('partner/getCurrentPartner', id);

    watch(
      () => currentPartner.value,
      () => {
        store.commit('storage/UPDATE_CURRENT_STORAGE_ITEM_ID', currentPartner.value?.mainStorage?.id);
        window.location.reload();
      }
    );
  };

  const logout = () => {
    store.dispatch('auth/logout');
  };

  return {
    storage,
    username,
    logout,
    storageList,
    userPartnersList,
    changeCurrentStorage,
    changeCurrentUserPartner,
    userIsPartner,
    partnerId,
    currentPartner,
  };
}

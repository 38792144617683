/* eslint-disable */
import { defaultApiInstance } from '@/compositions/api';
import qs from 'qs';
import { toRaw } from 'vue';

export function getShipmentList(params) {
  return defaultApiInstance.get(`v1/shipment`, { params });
}

export function getShipmentListForBooking(params) {
  return defaultApiInstance.get(`v1/shipment/for/booking`, { params });
}

export function createShipmentSchedule(data) {
  return defaultApiInstance.post(`v1/shipment/create/check`, JSON.stringify(data));
}

export function updateShipmentSchedule(data) {
  return defaultApiInstance.post(`v1/shipment/create`, JSON.stringify(data));
}

export function setShipmentStatus(id, data) {
  return defaultApiInstance.patch(`v1/shipment/${id}/status`, JSON.stringify(data));
}

export function getShipmentExport(params) {
  return defaultApiInstance.get(`v1/shipment/export`, { params });
}

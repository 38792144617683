/* eslint-disable */
import { defaultApiInstance } from '@/compositions/api';

export function getIncidentList(params) {
  return defaultApiInstance.get(`v1/incident`, { params });
}

export function getIncidentItem(id) {
  return defaultApiInstance.get(`v1/incident/${id}`);
}

export function createIncidentItem(data) {
  return defaultApiInstance.post(`v1/incident/create`, JSON.stringify(data));
}

export function getIncidentExport(params) {
  return defaultApiInstance.get(`v1/incident/export`, { params });
}

/* eslint-disable */
import { defaultApiInstance } from '@/compositions/api';

export function getOutboundList(data) {
  return defaultApiInstance.get(`v1/outbound/`, {
    params: {
      storageId: data.storageId,
      offset: data.offset,
      limit: data.limit,
      active: data.active,
      name: data.name,
      directionId: data.directionId
    },
  });
}

export function createOutboundItem(data) {
  return defaultApiInstance.post(`v1/outbound/`, JSON.stringify(data));
}

export function updateOutboundItem(id, data) {
  return defaultApiInstance.put(`v1/outbound/${id}`, JSON.stringify(data));
}

export function deactivateOutboundItem(id, data) {
  return defaultApiInstance.patch(`v1/outbound/${id}/deactivate`, JSON.stringify(data));
}

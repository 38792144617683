<template>
  <!-- eslint-disable -->
  <svg width="24" height="24" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.9473 32.2632H35.0001M9 32.2632L11.0527 34.3159L15.158 30.2106M19.9473 22.158H35.0001M9 22.1579L11.0527 24.2106L15.158 20.1053M19.9473 12.0527H35.0001M9 12.0527L11.0527 14.1053L15.158 10M2 2H42V42H2V2Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

/* eslint-disable */
import { defaultApiInstance } from '@/compositions/api';

export function getCapacityWeekList(params) {
  let queryString = '';

  if (params?.limit) {
    queryString += `?limit=${params.limit}`;

    if (params?.offset) {
      queryString += `&offset=${params.offset}`;
    }
  }

  return defaultApiInstance.get(`v1/capacity/week/restrictionType/${params.restrictionId}${queryString}`);
}

export function getCapacityShifts(params){
  return defaultApiInstance.get(`v1/shift/search?storageId=${params.storageId}&date=${params.date}`);
}

export function createCapacityWeekItem(data) {
  return defaultApiInstance.post(`v1/capacity/week`, JSON.stringify(data));
}

export function getCapacityForecast(capacityId) {
  return defaultApiInstance.get(`v1/capacity/${capacityId}/forecast`);
}

export function getCapacityBooking(capacityId) {
  return defaultApiInstance.get(`v1/capacity/${capacityId}/booking`);
}

export function updateCapacityWeekItem(weekId, data) {
  return defaultApiInstance.patch(`v1/capacity/week/${weekId}`, JSON.stringify(data));
}

export function updateCapacityShiftRestrictionCount(shiftId, data) {
  return defaultApiInstance.put(`v1/capacity/shift/${shiftId}/restrictionCount`, JSON.stringify(data));
}

export function updateCapacityShiftZeroRoom(shiftId, data) {
  return defaultApiInstance.put(`v1/capacity/shift/${shiftId}/zeroroom`, JSON.stringify(data));
}

export function updateCapacityShiftRepackaging(shiftId, data) {
  return defaultApiInstance.put(`v1/capacity/shift/${shiftId}/repackaging`, JSON.stringify(data));
}

export function updateCapacityShiftPlanCount(shiftId, data) {
  return defaultApiInstance.put(`v1/capacity/shift/${shiftId}/planCount`, JSON.stringify(data));
}

export function updateCapacityTypeCooperationCount(typeCooperationId, data) {
  return defaultApiInstance.put(`v1/capacityTypeCooperation/${typeCooperationId}/count`, JSON.stringify(data));
}

export function updateCapacityTypeCooperationCountHand(data) {
  return defaultApiInstance.put(`v1/capacityTypeCooperationWeek/planCountHand`, JSON.stringify(data));
}

export function getCapacityPlanImport(params) {
  return defaultApiInstance.get(`v1/plan/import`, { params });
}

export function createCapacityPlanImport(data) {
  return defaultApiInstance.post(`v1/plan/import`, JSON.stringify(data));
}

export function updateCapacityPlanImport(planImportId, data) {
  return defaultApiInstance.put(`v1/plan/import/${planImportId}`, JSON.stringify(data));
}

export function deleteCapacityPlanImport(data) {
  return defaultApiInstance.delete(`v1/plan/import/delete`, { data: JSON.stringify(data) });
}

/* eslint-disable */
import { getChangeLogList } from '@/methods/changeLog';
import store from '@/store';
import router from '@/router';

const state = {
  list: [],
  currentPage: 1,
  filters: {},
  dataIsUpdated: false,
};

const getters = {
  getChangeLogList(state) {
    return state.list;
  },
  getChangeLogFilters(state) {
    return state.filters;
  },
};

const mutations = {
  UPDATE_CHANGE_LOG_LIST(state, data) {
    state.list = data;
  },
  CHANGE_LOG_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated = data;
  },
  UPDATE_CURRENT_PAGE(state, data) {
    state.currentPage = data;
  },
  SET_FILTERS(state, data) {
    state.filters = data;
  },
};

const actions = {
  getChangeLogList({ commit, state }, params) {
    getChangeLogList(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_CHANGE_LOG_LIST', response.data.data);
          commit('privilege/UPDATE_CHANGE_LOG_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          store.commit('auth/UPDATE_CONTENT_AVAILABILITY', false);

          if (!router.currentRoute.value.query?.forbidden) {
            router
              .replace({
                name: router.currentRoute.value.name,
                query: { ...router.currentRoute.value.query, forbidden: true },
              })
              .then(() => {
                location.reload();
              });
          }
        }
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

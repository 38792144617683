/* eslint-disable */
import {
  getBookingItemComments,
  createBookingItemComment,
  updateBookingItemComment,
  deleteBookingItemComment,
} from '@/methods/bookingComment';
import { ElNotification } from 'element-plus';
import { getErrorMessage } from '@/compositions/helpers/helpers';

const state = {
  list: [],
  dataIsUpdated: false,
};

const getters = {};

const mutations = {
  UPDATE_BOOKING_ITEM_COMMENTS_LIST(state, data) {
    state.list = data;
  },
  BOOKING_ITEM_COMMENTS_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated = data;
  },
};

const actions = {
  getBookingItemComments({ commit, state }, params) {
    getBookingItemComments(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_BOOKING_ITEM_COMMENTS_LIST', response.data.data);
        commit('privilege/UPDATE_BOOKING_COMMENT_PRIVILEGE', response.data.privilege, { root: true });
      }
    });
  },

  createBookingItemComment({ commit, dispatch, state }, params) {
    createBookingItemComment(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('BOOKING_ITEM_COMMENTS_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  updateBookingItemComment({ commit, dispatch, state }, { id, data }) {
    updateBookingItemComment(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('BOOKING_ITEM_COMMENTS_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  deleteBookingItemComment({ commit, dispatch, state }, { id }) {
    deleteBookingItemComment(id)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('BOOKING_ITEM_COMMENTS_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import { defaultApiInstance } from '@/compositions/api';

export function getCarOrders(payload) {
  return defaultApiInstance.get(`v1/carOrder`, { params: payload });
}

export function getShipmentHolds(payload) {
  return defaultApiInstance.get(`v1/carOrder/supply/availableHoldings?ids=${payload}`);
}

export function getReturnHolds(payload) {
  return defaultApiInstance.get(`v1/carOrder/return/availableHoldings?ids=${payload}`);
}

export function confirmSingleCarHold(payload) {
  return defaultApiInstance.patch(`v1/carOrder/${payload.id}/approve`, payload.data);
}

export function confirmSmallDeliveryHolds(payload) {
  return defaultApiInstance.patch(`v1/carOrder/binding/small/deliveries`, payload);
}

export function carHoldChancel(payload) {
  return defaultApiInstance.patch(`v1/carOrder/${payload.id}/cancel`,payload.data);
}

export function updateDriverData(payload) {
  return defaultApiInstance.patch(`v1/carOrder/contacts`, payload);
}

export function getTableExport(payload) {
  return defaultApiInstance.get('v1/carOrder/export', { params: payload })
}

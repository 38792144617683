<template>
  <!-- eslint-disable -->
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 14.5C11.8674 14.5 11.7402 14.4473 11.6464 14.3536L7.64645 10.3536C7.45119 10.1583 7.45119 9.84171 7.64645 9.64645C7.84171 9.45118 8.15829 9.45118 8.35355 9.64645L12 13.2929L15.6464 9.64645C15.8417 9.45118 16.1583 9.45118 16.3536 9.64645C16.5488 9.84171 16.5488 10.1583 16.3536 10.3536L12.3536 14.3536C12.2598 14.4473 12.1326 14.5 12 14.5Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000000'
    },
  },
};
</script>

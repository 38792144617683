<script>
import Menu from '@/components/blocks/menu/Menu';
import Header from '@/components/blocks/header/Header';
import { computed } from 'vue';
import { useStore } from "vuex";

export default {
  components: {
    Menu,
    Header,
  },
  setup() {
    const store = useStore();
    const contentIsAvailable = computed(() => store.state.auth.contentIsAvailable);

    return {
      contentIsAvailable
    }
  }
};
</script>

<template>
  <div class="page --has-sidebar">
    <Header />
    <Menu />
    <div class="content">
      <div
        v-loading="!contentIsAvailable"
        class="container"
      >
        <router-view />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>

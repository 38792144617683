/* eslint-disable */

import { getObjectPrivilege } from '@/methods/privilege';
import { getDenyPrivilegeObject } from '@/compositions/helpers/helpers';

const state = {
  booking: {},
  bookingInterrogation: {},
  bookingExtendedWork: {},
  bookingOverRestrictions: {},
  bookingComment: {},
  bookingHistory: {},
  bookingTag: {},
  shipment: {},
  shipmentStatus: {},
  returns: {},
  inbound: {},
  outbound: {},
  partner: {},
  managePartnerUser: {},
  ratingMatrix: {},
  timeMatrix: {},
  incident: {},
  calendar: {},
  baseCalendar: {},
  lamodaCarRegistry: {},
  carRegistry: {},
  slotSubscription: {},
  settings: {
    storage: {},
    gate: {},
    direction: {},
    documentType: {},
    cooperationType: {},
    breakTime: {},
    workTime: {},
    slotSize: {},
    storageSettings: {},
    restrictionType: {},
    directionDocuments: {},
    termLateReturn: {},
    partnerRestrictions: {},
    buildingGate: {},
    slotSizeByDirection: {},
    capacityWeekList: {},
    capacityForecast: {},
    capacityBooking: {},
    capacityPlanImport: {},
    restrictionRange: {},
    restrictionCar: {},
    restrictionCarExtended: {},
    partnerRating: {},
    commercialRating: {},
    slotGrade: {},
  },
  changeLog: {},
};

const getters = {};

const mutations = {
  UPDATE_BOOKING_PRIVILEGE(state, data) {
    state.booking = data;
  },
  UPDATE_BOOKING_INTERROGATION_PRIVILEGE(state, data) {
    state.bookingInterrogation = data;
  },
  UPDATE_BOOKING_EXTENDED_WORK_PRIVILEGE(state, data) {
    state.bookingExtendedWork = data;
  },
  UPDATE_BOOKING_OVER_RESTRICTIONS_PRIVILEGE(state, data) {
    state.bookingOverRestrictions = data;
  },
  UPDATE_BOOKING_COMMENT_PRIVILEGE(state, data) {
    state.bookingComment = data;
  },
  UPDATE_BOOKING_HISTORY_PRIVILEGE(state, data) {
    state.bookingHistory = data;
  },
  UPDATE_BOOKING_TAG_PRIVILEGE(state, data) {
    state.bookingTag = data;
  },
  UPDATE_SHIPMENT_PRIVILEGE(state, data) {
    state.shipment = data;
  },
  UPDATE_SHIPMENT_STATUS_PRIVILEGE(state, data) {
    state.shipmentStatus = data;
  },
  UPDATE_RETURN_PRIVILEGE(state, data) {
    state.returns = data;
  },
  UPDATE_INBOUND_PRIVILEGE(state, data) {
    state.inbound = data;
  },
  UPDATE_OUTBOUND_PRIVILEGE(state, data) {
    state.outbound = data;
  },
  UPDATE_PARTNER_PRIVILEGE(state, data) {
    state.partner = data;
  },
  UPDATE_MANAGE_PARTNER_USER_PRIVILEGE(state, data) {
    state.managePartnerUser = data;
  },
  UPDATE_RATING_MATRIX_PRIVILEGE(state, data) {
    state.ratingMatrix = data;
  },
  UPDATE_TIME_MATRIX_PRIVILEGE(state, data) {
    state.timeMatrix = data;
  },
  UPDATE_INCIDENT_PRIVILEGE(state, data) {
    state.incident = data;
  },
  UPDATE_LAMODA_CAR_REGISTRY_PRIVILEGE(state, data) {
    state.lamodaCarRegistry = data;
  },
  UPDATE_CAR_REGISTRY_PRIVILEGE(state, data) {
    state.carRegistry = data;
  },
  UPDATE_CALENDAR_PRIVILEGE(state, data) {
    state.calendar = data;
  },
  UPDATE_BASE_CALENDAR_PRIVILEGE(state, data) {
    state.baseCalendar = data;
  },
  UPDATE_SLOT_SUBSCRIPTION_PRIVILEGE(state, data) {
    state.slotSubscription = data;
  },
  UPDATE_SETTINGS_STORAGE_PRIVILEGE(state, data) {
    state.settings.storage = data;
  },
  UPDATE_SETTINGS_GATE_PRIVILEGE(state, data) {
    state.settings.gate = data;
  },
  UPDATE_SETTINGS_DIRECTION_PRIVILEGE(state, data) {
    state.settings.direction = data;
  },
  UPDATE_SETTINGS_COOPERATION_TYPE_PRIVILEGE(state, data) {
    state.settings.cooperationType = data;
  },
  UPDATE_SETTINGS_DOCUMENT_TYPE_PRIVILEGE(state, data) {
    state.settings.documentType = data;
  },
  UPDATE_SETTINGS_BREAK_TIME_PRIVILEGE(state, data) {
    state.settings.breakTime = data;
  },
  UPDATE_SETTINGS_WORK_TIME_PRIVILEGE(state, data) {
    state.settings.workTime = data;
  },
  UPDATE_SETTINGS_STORAGE_SETTINGS_PRIVILEGE(state, data) {
    state.settings.storageSettings = data;
  },
  UPDATE_SETTINGS_RESTRICTION_TYPE_PRIVILEGE(state, data) {
    state.settings.restrictionType = data;
  },
  UPDATE_SETTINGS_DIRECTION_DOCUMENTS_PRIVILEGE(state, data) {
    state.settings.directionDocuments = data;
  },
  UPDATE_SETTINGS_TERM_LATE_RETURN_PRIVILEGE(state, data) {
    state.settings.termLateReturn = data;
  },
  UPDATE_SETTINGS_PARTNER_RESTRICTIONS_PRIVILEGE(state, data) {
    state.settings.partnerRestrictions = data;
  },
  UPDATE_SETTINGS_BUILDING_GATE_PRIVILEGE(state, data) {
    state.settings.buildingGate = data;
  },
  UPDATE_SETTINGS_SLOT_SIZE_BY_DIRECTION_PRIVILEGE(state, data) {
    state.settings.slotSizeByDirection = data;
  },
  UPDATE_SETTINGS_CAPACITY_WEEK_LIST_PRIVILEGE(state, data) {
    state.settings.capacityWeekList = data;
  },
  UPDATE_SETTINGS_CAPACITY_FORECAST_PRIVILEGE(state, data) {
    state.settings.capacityForecast = data;
  },
  UPDATE_SETTINGS_CAPACITY_BOOKING_PRIVILEGE(state, data) {
    state.settings.capacityBooking = data;
  },
  UPDATE_SETTINGS_CAPACITY_PLAN_IMPORT_PRIVILEGE(state, data) {
    state.settings.capacityPlanImport = data;
  },
  UPDATE_SETTINGS_RESTRICTION_RANGE_PRIVILEGE(state, data) {
    state.settings.restrictionRange = data;
  },
  UPDATE_SETTINGS_RESTRICTION_CAR_PRIVILEGE(state, data) {
    state.settings.restrictionCar = data;
  },
  UPDATE_SETTINGS_RESTRICTION_CAR_EXTENDED_PRIVILEGE(state, data) {
    state.settings.restrictionCarExtended = data;
  },
  UPDATE_SETTINGS_PARTNER_RATING_PRIVILEGE(state, data) {
    state.settings.partnerRating = data;
  },
  UPDATE_SETTINGS_COMMERCIAL_RATING_PRIVILEGE(state, data) {
    state.settings.commercialRating = data;
  },
  UPDATE_SETTINGS_SLOT_GRADE_PRIVILEGE(state, data) {
    state.settings.slotGrade = data;
  },
  UPDATE_CHANGE_LOG_PRIVILEGE(state, data) {
    state.changeLog = data;
  },
};

const actions = {
  getObjectPrivilege({ commit, state }, params) {
    getObjectPrivilege(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        switch (response.data.data?.object) {
          case 'SlotSizeByDirection':
            commit('privilege/UPDATE_SETTINGS_SLOT_SIZE_BY_DIRECTION_PRIVILEGE', response.data.privilege, {
              root: true,
            });
            break;
          case 'RatingMatrix':
            commit('privilege/UPDATE_RATING_MATRIX_PRIVILEGE', response.data.privilege, { root: true });
            break;
          case 'Booking':
            commit('privilege/UPDATE_BOOKING_PRIVILEGE', response.data.privilege, { root: true });
            break;
          case 'BookingExtendedWork':
            commit('privilege/UPDATE_BOOKING_EXTENDED_WORK_PRIVILEGE', response.data.privilege, { root: true });
            break;
          case 'DocumentDirectionCooperation':
            commit('privilege/UPDATE_SETTINGS_DIRECTION_DOCUMENTS_PRIVILEGE', response.data.privilege, { root: true });
            break;
          case 'CapacityWeek':
            commit('privilege/UPDATE_SETTINGS_CAPACITY_WEEK_LIST_PRIVILEGE', response.data.privilege, { root: true });
            break;
          case 'RestrictionCar':
            commit('privilege/UPDATE_SETTINGS_RESTRICTION_CAR_PRIVILEGE', response.data.privilege, { root: true });
            break;
          case 'PlanningRange':
            commit('privilege/UPDATE_SETTINGS_RESTRICTION_RANGE_PRIVILEGE', response.data.privilege, { root: true });
            break;
          case 'Partner':
            commit('privilege/UPDATE_PARTNER_PRIVILEGE', response.data.privilege, { root: true });
            break;
          case 'Incident':
            commit('privilege/UPDATE_INCIDENT_PRIVILEGE', response.data.privilege, { root: true });
            break;
          case 'ReturnNc':
            commit('privilege/UPDATE_RETURN_PRIVILEGE', response.data.privilege, { root: true });
            break;
          case 'SlotInterrogationRequest':
            commit('privilege/UPDATE_BOOKING_INTERROGATION_PRIVILEGE', response.data.privilege, { root: true });
            break;
          case 'BookingOverRestrictions':
            commit('privilege/UPDATE_BOOKING_OVER_RESTRICTIONS_PRIVILEGE', response.data.privilege, { root: true });
            break;
          case 'BookingCar':
            commit('privilege/UPDATE_CAR_REGISTRY_PRIVILEGE', response.data.privilege, { root: true });
            break;
          case 'InternalHolding':
            commit('privilege/UPDATE_LAMODA_CAR_REGISTRY_PRIVILEGE', response.data.privilege, { root: true });
            break;
          case 'BaseCalendar':
            commit('privilege/UPDATE_BASE_CALENDAR_PRIVILEGE', response.data.privilege, { root: true });
            break;
          case 'Slot':
            commit('privilege/UPDATE_CALENDAR_PRIVILEGE', response.data.privilege, { root: true });
            break;
          case 'SuppliesStatus':
            commit('privilege/UPDATE_SHIPMENT_STATUS_PRIVILEGE', response.data.privilege, { root: true });
            break;
          case 'SlotSubscription':
            commit('privilege/UPDATE_SLOT_SUBSCRIPTION_PRIVILEGE', response.data.privilege, { root: true });
            break;
        }
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<script>
/* eslint-disable */
import { header } from '@/compositions/blocks/header';
import ArrowDownSmall from '@/components/common/icons/ArrowDownSmall';
/* eslint-enable */

export default {
  components: {
    ArrowDownSmall
  },
  setup() {
    const {
      storage,
      storageList,
      userPartnersList,
      changeCurrentStorage,
      changeCurrentUserPartner,
      userIsPartner,
      username,
      logout,
      partnerId,
      currentPartner
    } = header();

    return {
      storage,
      storageList,
      userPartnersList,
      changeCurrentStorage,
      changeCurrentUserPartner,
      userIsPartner,
      username,
      logout,
      partnerId,
      currentPartner
    };
  },
  computed:{
    getCurrentPartner() {
      let partner = ''
      if (this.userPartnersList) {
        this.userPartnersList.forEach(elem => {
          if (elem.id === this.partnerId) {
            partner = elem.name
          }
        })
      }
      return partner
    }
  }

};
</script>

<template>
  <!-- eslint disable -->
  <div class="header">
    <div class="header__container">
      <div class="header__logo">
        <img
          src="@/assets/images/logos/logo.svg"
          alt=""
        >
      </div>

      <div
        v-if="username"
        class="header__user items-center"
      >
        <el-dropdown

          v-if="userIsPartner && userPartnersList && userPartnersList.length"
          class="mr-24"
        >
          <span class="el-dropdown-link text-blue-azure">
            Выбрать партнёра

            <el-icon class="el-icon--right">
              <ArrowDownSmall color="#0098FF" />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item

                v-for="(userPartner, idx) in userPartnersList"
                :key="idx"
                @click="changeCurrentUserPartner(userPartner.id)"
              >
                {{ userPartner.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <span
          v-if="getCurrentPartner"
          class="mr-24 mt-8"
        >
          {{ getCurrentPartner }}
        </span>
        <el-dropdown
          v-if="!userIsPartner || (userIsPartner && currentPartner?.storages && currentPartner?.storages.length > 1)"
          class="mr-24"
        >
          <span class="el-dropdown-link text-blue-azure">
            Выбрать другой склад
            <el-icon class="el-icon--right">
              <ArrowDownSmall color="#0098FF" />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu v-if="!userIsPartner">
              <el-dropdown-item
                v-for="(storage, idx) in storageList"
                :key="idx"
                @click="changeCurrentStorage(storage.id)"
              >
                {{ storage.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
            <el-dropdown-menu v-if="userIsPartner">
              <el-dropdown-item
                v-for="(storage, idx) in currentPartner?.storages"
                :key="idx"
                @click="changeCurrentStorage(storage.id)"
              >
                {{ storage.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <span
          v-if="storage.name"
          class="mr-24 mt-8"
        >
          {{ storage.name }}
        </span>
        <el-dropdown>
          <span class="el-dropdown-link">
            {{ username }}
            <el-icon class="el-icon--right">
              <ArrowDownSmall />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="logout">
                Выход
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<style src="./header.scss" lang="scss" scoped></style>

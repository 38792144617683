import { defaultApiInstance } from '@/compositions/api';

export function getRatingMatrix() {
  return defaultApiInstance.get(`v1/ratingMatrix`);
}

export function createRatingMatrix(data) {
  return defaultApiInstance.post(`v1/ratingMatrix/create`, data);
}

export function deleteRatingMatrix(id) {
  return defaultApiInstance.delete(`v1/ratingMatrix/${id}/delete`);
}

export function updateRatingMatrix(params) {
  return defaultApiInstance.put(`v1/ratingMatrix/${params.id}/update`, params.params);
}

/* eslint-disable */
import {
  getCooperationTypes,
  getMyCooperationTypes,
  createCooperationType,
  updateCooperationType,
  activateCooperationType,
  deactivateCooperationType,
} from '@/methods/cooperationType';
import { ElNotification } from 'element-plus';
import { getDenyPrivilegeObject, getErrorMessage } from '@/compositions/helpers/helpers';

const state = {
  list: [],
  myList: [],
  item: {},
  dataIsUpdated: false,
};

const getters = {
  getCooperationTypes(state) {
    return state.list;
  },
  getMyCooperationTypes(state) {
    return state.myList;
  },
};

const mutations = {
  UPDATE_COOPERATION_TYPES(state, data) {
    state.list = data;
  },
  UPDATE_MY_COOPERATION_TYPES(state, data) {
    state.myList = data;
  },
  ACTIVATE_COOPERATION_TYPE(state, id) {
    state.list = state.list.map((item) => {
      if (item.id === id) {
        item.active = true;
      }

      return item;
    });
  },
  DEACTIVATE_COOPERATION_TYPE(state, id) {
    state.list = state.list.map((item) => {
      if (item.id === id) {
        item.active = false;
      }

      return item;
    });
  },
  COOPERATION_TYPES_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated = data;
  },
};

const actions = {
  getCooperationTypes({ commit, state }, params) {
    getCooperationTypes(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_COOPERATION_TYPES', response.data.data);
          commit('privilege/UPDATE_SETTINGS_COOPERATION_TYPE_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_COOPERATION_TYPE_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  getMyCooperationTypes({ commit, state }, params) {
    getMyCooperationTypes(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_MY_COOPERATION_TYPES', response.data.data);
          commit('privilege/UPDATE_SETTINGS_COOPERATION_TYPE_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_COOPERATION_TYPE_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  createCooperationType({ commit, dispatch, state }, params) {
    createCooperationType(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('COOPERATION_TYPES_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  updateCooperationType({ commit, dispatch, state }, { id, data }) {
    updateCooperationType(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('COOPERATION_TYPES_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  activateCooperationType({ commit, state }, params) {
    activateCooperationType(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('ACTIVATE_COOPERATION_TYPE', params);

          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  deactivateCooperationType({ commit, state }, params) {
    deactivateCooperationType(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('DEACTIVATE_COOPERATION_TYPE', params);

          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import { setBooking, cancelBooking } from '@/methods/bookingSlot';
import { ElNotification } from 'element-plus';
import { getErrorMessage } from '@/compositions/helpers/helpers';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  setBooking({ commit, state }, params) {
    setBooking(params)
      .then((res) => {
        if ('success' in res.data && res.data.success) {
          ElNotification.success({
            message: res.data.message,
            duration: 2000,
          });
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
  cancelBooking({ commit, state }, params) {
    cancelBooking(params)
      .then((res) => {
        if ('success' in res.data && res.data.success) {
          ElNotification.success({
            message: res.data.message,
            duration: 2000,
          });
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

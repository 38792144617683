import { computed } from 'vue';
import auth from '@/store/modules/Auth';

const userAvailableSections = computed(() => auth.state.availableSections);

const checkAvailability = (code) => {
  return Object.values(userAvailableSections.value).includes(code);
};

const state = {
  routes: [
    {
      name: 'Главная',
      code: 'dashboard',
      route: '/dashboard',
      icon: 'building',
      active: false,
      isDisabled: false,
      isAvailable: checkAvailability('dashboard'),
    },
    {
      name: 'Поставки и возвраты',
      code: 'shipment-and-return',
      route: '/shipment-and-return',
      icon: 'box',
      active: false,
      isDisabled: false,
      isAvailable: checkAvailability('shipment-and-return'),
    },
    {
      name: 'Календарь',
      code: 'calendar',
      route: '/calendar',
      icon: 'calendar',
      active: false,
      isDisabled: false,
      isAvailable: checkAvailability('calendar') || checkAvailability('base-calendar'),
    },
    {
      name: 'Реестр броней',
      code: 'booking',
      route: '/booking',
      icon: 'booking',
      active: false,
      isDisabled: false,
      isAvailable: checkAvailability('booking'),
    },
    {
      name: 'Реестр обращений',
      code: 'incident',
      route: '/incident',
      icon: 'communicate',
      active: false,
      isDisabled: false,
      isAvailable: checkAvailability('incident'),
    },
    {
      name: 'Гейты',
      code: 'gate',
      route: '/gate',
      icon: 'gate',
      active: false,
      isDisabled: false,
      isAvailable: checkAvailability('gate'),
    },
    {
      name: 'Внутренние маршруты',
      code: 'routes',
      route: '/routes',
      icon: 'route',
      active: false,
      isDisabled: false,
      isAvailable: checkAvailability('routes'),
    },
    {
      name: 'Настройки склада',
      code: 'storage',
      route: '/storage',
      icon: 'warehouse',
      active: false,
      isDisabled: false,
      isAvailable: checkAvailability('storage'),
    },
    {
      name: 'Настройки',
      code: 'settings',
      route: '/settings',
      icon: 'options',
      active: false,
      isDisabled: false,
      isAvailable: checkAvailability('settings') || checkAvailability('time-expences-matrix'),
    },
    {
      name: 'Реестр партнеров',
      code: 'partners-registry',
      route: '/partners-registry',
      icon: 'partners',
      active: false,
      isDisabled: false,
      isAvailable: checkAvailability('partners-registry'),
    },
    {
      name: 'Заявки на машину и холдирования',
      code: 'car-registry',
      route: '/car-registry',
      icon: 'CarRegistry',
      active: false,
      isDisabled: false,
      isAvailable: checkAvailability('car-registry') || checkAvailability('lamodacar-registry'),
    },
    {
      name: 'История изменений в системе',
      code: 'changelog',
      route: '/changelog',
      icon: 'changelog',
      active: false,
      isDisabled: false,
      isAvailable: checkAvailability('changelog'),
    },
  ],
};

const mutations = {
  UPDATE_NAVBAR_ROUTES(state, value) {
    const currentRouteParent = value.split('/')[1];

    state.routes.forEach((route) => {
      route.route.split('/')[1] === currentRouteParent ? (route.active = true) : (route.active = false);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};

/* eslint-disable */
import {
  getBookingList,
  getBookingItem,
  getBookingItemHistory,
  getBookingItemGates,
  createBookingShipmentItem,
  createBookingShipmentItemNoLimits,
  createBookingReturnItem,
  createBookingReturnItemNoLimits,
  createBookingInboundItem,
  createBookingInboundItemNoLimits,
  createBookingOutboundItem,
  createBookingOutboundItemNoLimits,
  createSlotSubscription,
  updateBookingShipmentItem,
  updateBookingReturnItem,
  updateBookingInboundItem,
  updateBookingOutboundItem,
  updateBookingSupplyWithLamodaCar,
  updateBookingReturnWithLamodaCar,
  updateBookingShipmentInterrogationItem,
  updateBookingReturnInterrogationItem,
  updateFactCorrection,
  saveBookingItem,
  getBookingItemTransitionStatus,
  getBookingItemShipmentsLeftData,
  getBookingItemShipmentsRightData,
  getBookingItemReturnsLeftData,
  getBookingItemInboundLeftData,
  getBookingItemOutboundLeftData,
  getBookingAvailableSlots,
  getBookingAvailableSlotsSearch,
  getBookingAvailableSlotsInterrogation,
  getSlotSubscription,
  getBookingPeriod,
  getTimeBookingSupplyWithLamodaCar,
  getTimeBookingReturnWithLamodaCar,
  getBookingExport,
  checkBookingSupplyItemSlots,
  checkBookingReturnItemSlots,
  changeBookingItemStatus,
  changeBookingStatusOnly,
  changeBookingItemDriverArrivedStatus,
  changeBookingItemDocumentStatus,
  changeBookingItemDocumentComment,
  changeBookingItemStatusDocumentsAccepted,
  changeBookingItemStatusGateCheckIn,
  changeBookingItemCarProcessingStatus,
  changeBookingItemProcessingDoneStatus,
  changeBookingItemDriverReleasedStatus,
  changeBookingItemCancelByManagerStatus,
  changeBookingItemCarParkingStatus,
  createBookingLamodaCar,
  createBookingReturnLamodaCar,
  getInterrogationBookingList,
  approveInterrogationBookingItem,
  cancelInterrogationBookingItem,
  cancelBookingItems,
  getSlotSubscriptionAvailableDates,
  checkBookingWithLamodaCarReturnItemSlots,
  checkBookingWithLamodaCarSupplyItemSlots,
} from '@/methods/booking';
import { ElNotification } from 'element-plus';
import store from '@/store';
import router from '@/router';
import { formatDate, getDenyPrivilegeObject, getErrorMessage } from '@/compositions/helpers/helpers';
import { toRaw } from 'vue';

const state = {
  list: [],
  interrogationList: [],
  item: {},
  itemHistory: [],
  availableSlots: [],
  availableSlotsSearch: [],
  availableSlotsInterrogation: [],
  availableSlotsByParams: [],
  availableSubscriptionDates: [],
  periodList: [],
  dataIsUpdated: false,
  loadingSuccess: false,
  currentPage: 1,
  bookingSuppyWithLamodaCarTime: null,
  bookingReturnWithLamodaCarTime: null,
  currentDirectionType: null,
  isLoading: false,
  modalSelectSlotsIsShown: false,
  modalSelectSubscriptionSlotsIsShown: false,
  slotSubscription: {},

  filters: {
    Статус: [
      'Новая',
      'Ожидает согласования',
      'Согласована',
      'Водитель прибыл',
      'Документы приняты',
      'Проверка с колес',
      'Обработка машины',
      'Согласована: необходимо дополнить заявку',
      'Проблема с документами: ожидает согласования',
      'Машина отправлена на парковку',
      'Постановка на ворота',
      'Обработка завершена',
    ],
    dateStart: formatDate(new Date(Date.now() - 86400000)) + 'T00:00:00',
  },
};

const getters = {
  getBookingList(state) {
    return state.list;
  },
  getFilters(state) {
    return state.filters;
  },
  getCurrentPage(state) {
    return state.currentPage;
  },
  getCurrentDirectionType(state) {
    return state.currentDirectionType;
  },
};

const mutations = {
  UPDATE_BOOKING_LIST(state, data) {
    state.list = data;
  },
  UPDATE_CURRENT_DIRECTION_TYPE(state, data) {
    state.currentDirectionType = data;
  },
  UPDATE_INTERROGATION_BOOKING_LIST(state, data) {
    state.interrogationList = data;
  },
  UPDATE_BOOKING_ITEM(state, data) {
    state.item = data;
  },
  UPDATE_BOOKING_ITEM_HISTORY(state, data) {
    state.itemHistory = data;
  },
  UPDATE_BOOKING_ITEM_GATES(state, data) {
    state.item.gates = data;

    if (state.item.gates.length) {
      state.item.gates.sort((a, b) => a.statusId - b.statusId);
    }
  },
  UPDATE_BOOKING_ITEM_TRANSITION_STATUS(state, data) {
    state.item.transitionStatus = data;
  },
  UPDATE_BOOKING_ITEM_LEFT_DATA(state, data) {
    state.item.leftData = data;
  },
  UPDATE_BOOKING_ITEM_RIGHT_DATA(state, data) {
    state.item.rightData = data;

    if (Object.values(state.item.rightData.documentsAtReception).length) {
      state.item.rightData.documentsAtReception = Object.values(state.item.rightData.documentsAtReception);

      state.item.rightData.documentsAtReception.map((document) => {
        document.isOpen = false;

        return document;
      });
    }

    if (Object.values(state.item.rightData.documentsOnResult).length) {
      state.item.rightData.documentsOnResult = Object.values(state.item.rightData.documentsOnResult);

      state.item.rightData.documentsOnResult.map((document) => {
        document.isOpen = false;

        return document;
      });
    }
  },
  UPDATE_BOOKING_AVAILABLE_SLOTS_DATA(state, data) {
    state.availableSlots = data;
  },
  UPDATE_BOOKING_AVAILABLE_SLOTS_SEARCH_DATA(state, data) {
    state.availableSlotsSearch = data;
  },
  UPDATE_BOOKING_AVAILABLE_SLOTS_INTERROGATION_DATA(state, data) {
    state.availableSlotsInterrogation = data;
  },
  UPDATE_BOOKING_AVAILABLE_SLOTS_BY_PARAMS_DATA(state, data) {
    state.availableSlotsByParams = data;
  },
  UPDATE_BOOKING_AVAILABLE_SUBSCRIPTION_DATES(state, data) {
    state.availableSubscriptionDates = data;
  },
  BOOKING_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated = data;
  },
  UPDATE_LOADING_SUCCESS(state, data) {
    state.loadingSuccess = data;
  },
  UPDATE_PERIOD_LIST(state, data) {
    state.periodList = data;
  },
  UPDATE_FILTERS(state, data) {
    let stateKey = '';
    let newKey = '';
    const movedFilters = toRaw(state.filters);
    for (let val in toRaw(state.filters)) {
      stateKey = val;
    }
    for (let val in toRaw(data)) {
      newKey = val;
      movedFilters[newKey] = toRaw(data)[val];
    }
    state.filters = movedFilters;
  },
  UPDATE_CURRENT_PAGE(state, data) {
    state.currentPage = data;
  },
  UPDATE_BOOKING_SUPPLY_WITH_LAMODA_CAR_TIME(state, data) {
    state.bookingSuppyWithLamodaCarTime = data;
  },
  UPDATE_BOOKING_RETURN_WITH_LAMODA_CAR_TIME(state, data) {
    state.bookingReturnWithLamodaCarTime = data;
  },
  UPDATE_BOOKING_IS_LOADING(state, data) {
    state.isLoading = data;
  },
  UPDATE_BOOKING_MODAL_SELECT_SLOTS_IS_SHOWN_STATUS(state, data) {
    state.modalSelectSlotsIsShown = data;
  },
  UPDATE_BOOKING_MODAL_SELECT_SUBSCRIPTION_SLOTS_IS_SHOWN_STATUS(state, data) {
    state.modalSelectSubscriptionSlotsIsShown = data;
  },
  UPDATE_SLOT_SUBSCRIPTION_ITEM(state, data) {
    state.slotSubscription = data;
  },
};

const actions = {
  getBookingList({ commit, state }, params) {
    getBookingList(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_BOOKING_LIST', response.data.data);
          commit('privilege/UPDATE_BOOKING_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          store.commit('auth/UPDATE_CONTENT_AVAILABILITY', false);

          if (!router.currentRoute.value.query?.forbidden) {
            router
              .replace({
                name: router.currentRoute.value.name,
                query: { ...router.currentRoute.value.query, forbidden: true },
              })
              .then(() => {
                location.reload();
              });
          }
        }
      });
  },

  getBookingPeriod({ commit, state }) {
    getBookingPeriod().then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_PERIOD_LIST', response.data.data);
      }
    });
  },

  getBookingItem({ commit, state }, params) {
    getBookingItem(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_BOOKING_ITEM', response.data.data);
      }
    });
  },

  getBookingItemHistory({ commit, state }, params) {
    getBookingItemHistory(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_BOOKING_ITEM_HISTORY', response.data.data);
          commit('privilege/UPDATE_BOOKING_HISTORY_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_BOOKING_HISTORY_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  getBookingItemGates({ commit, state }, params) {
    getBookingItemGates(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_BOOKING_ITEM_GATES', response.data.data);
      }
    });
  },

  getBookingItemTransitionStatus({ commit, state }, params) {
    getBookingItemTransitionStatus(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_BOOKING_ITEM_TRANSITION_STATUS', response.data.data);
      }
    });
  },

  getBookingItemShipmentsLeftData({ commit, state }, params) {
    getBookingItemShipmentsLeftData(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_BOOKING_ITEM_LEFT_DATA', response.data.data);
      }
    });
  },

  getBookingItemShipmentsRightData({ commit, state }, params) {
    getBookingItemShipmentsRightData(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_BOOKING_ITEM_RIGHT_DATA', response.data.data);
      }
    });
  },

  getBookingItemReturnsLeftData({ commit, state }, params) {
    getBookingItemReturnsLeftData(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_BOOKING_ITEM_LEFT_DATA', response.data.data);
      }
    });
  },

  getBookingItemInboundLeftData({ commit, state }, params) {
    getBookingItemInboundLeftData(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_BOOKING_ITEM_LEFT_DATA', response.data.data);
      }
    });
  },

  getBookingItemOutboundLeftData({ commit, state }, params) {
    getBookingItemOutboundLeftData(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_BOOKING_ITEM_LEFT_DATA', response.data.data);
      }
    });
  },

  getBookingAvailableSlots({ commit, state }, params) {
    getBookingAvailableSlots(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        const result = Object.values(response.data.data);
        if (result.length) {
          commit(
            'UPDATE_BOOKING_AVAILABLE_SLOTS_DATA',
            result.sort((a, b) => new Date(a.startAt) - new Date(b.startAt))
          );
        } else {
          commit('UPDATE_BOOKING_AVAILABLE_SLOTS_DATA', []);
        }
      }
    });
  },

  getBookingAvailableSlotsSearch({ commit, state }, params) {
    getBookingAvailableSlotsSearch(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        const result = Object.values(response.data.data.data);

        if (result.length) {
          commit(
            'UPDATE_BOOKING_AVAILABLE_SLOTS_SEARCH_DATA',
            result.sort((a, b) => new Date(a.startAt) - new Date(b.startAt))
          );
        } else {
          commit('UPDATE_BOOKING_AVAILABLE_SLOTS_SEARCH_DATA', []);
        }
      }
    });
  },

  getBookingAvailableSlotsInterrogation({ commit, state }, params) {
    getBookingAvailableSlotsInterrogation(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        const result = Object.values(response.data.data);

        if (result.length) {
          commit(
            'UPDATE_BOOKING_AVAILABLE_SLOTS_INTERROGATION_DATA',
            result.sort((a, b) => new Date(a.startAt) - new Date(b.startAt))
          );
        } else {
          commit('UPDATE_BOOKING_AVAILABLE_SLOTS_INTERROGATION_DATA', []);
        }
      }
    });
  },

  getSlotSubscription({ commit, state }, id) {
    getSlotSubscription(id)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_SLOT_SUBSCRIPTION_ITEM', response.data.data);
          commit('privilege/UPDATE_SLOT_SUBSCRIPTION_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch(() => {
        if (error.response.status === 403) {
          store.commit('auth/UPDATE_CONTENT_AVAILABILITY', false);

          if (!router.currentRoute.value.query?.forbidden) {
            router
              .replace({
                name: router.currentRoute.value.name,
                query: { ...router.currentRoute.value.query, forbidden: true },
              })
              .then(() => {
                location.reload();
              });
          }
        } else {
          location.href = '/404';
        }
      });
  },

  getTimeBookingSupplyWithLamodaCar({ commit, state }, params) {
    getTimeBookingSupplyWithLamodaCar(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_BOOKING_SUPPLY_WITH_LAMODA_CAR_TIME', response.data.data);
      }
    });
  },

  getTimeBookingReturnWithLamodaCar({ commit, state }, params) {
    getTimeBookingReturnWithLamodaCar(params).then((response) => {
      if ('success' in response.data && response.data.success) {
        commit('UPDATE_BOOKING_RETURN_WITH_LAMODA_CAR_TIME', response.data.data);
      }
    });
  },

  getSlotSubscriptionAvailableDates({ commit, state }, params) {
    getSlotSubscriptionAvailableDates(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_BOOKING_AVAILABLE_SUBSCRIPTION_DATES', response.data.data?.dates);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });

        commit('UPDATE_BOOKING_MODAL_SELECT_SUBSCRIPTION_SLOTS_IS_SHOWN_STATUS', false);
      });
  },

  getBookingExport({ commit, state }, params) {
    getBookingExport(params).then((response) => {
      const downloadLink = document.createElement('a');
      const blob = new Blob(['\ufeff', response.data]);

      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `booking-data-${formatDate(new Date())}.csv`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  },

  checkBookingSupplyItemSlots({ commit, state }, params) {
    checkBookingSupplyItemSlots(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          const result = Object.values(response.data.data);
          if (result.length) {
            commit(
              'UPDATE_BOOKING_AVAILABLE_SLOTS_BY_PARAMS_DATA',
              result.sort((a, b) => new Date(a.startAt) - new Date(b.startAt))
            );
          } else {
            commit('UPDATE_BOOKING_AVAILABLE_SLOTS_BY_PARAMS_DATA', []);
          }
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });

        commit('UPDATE_BOOKING_MODAL_SELECT_SLOTS_IS_SHOWN_STATUS', false);
      });
  },

  checkBookingWithLamodaCarSupplyItemSlots({ commit, state }, params) {
    checkBookingWithLamodaCarSupplyItemSlots(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          const result = Object.values(response.data.data);
          if (result.length) {
            commit(
              'UPDATE_BOOKING_AVAILABLE_SLOTS_BY_PARAMS_DATA',
              result.sort((a, b) => new Date(a.startAt) - new Date(b.startAt))
            );
          } else {
            commit('UPDATE_BOOKING_AVAILABLE_SLOTS_BY_PARAMS_DATA', []);
          }
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });

        commit('UPDATE_BOOKING_MODAL_SELECT_SLOTS_IS_SHOWN_STATUS', false);
      });
  },

  checkBookingReturnItemSlots({ commit, state }, params) {
    checkBookingReturnItemSlots(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          const result = Object.values(response.data.data);
          if (result.length) {
            commit(
              'UPDATE_BOOKING_AVAILABLE_SLOTS_BY_PARAMS_DATA',
              result.sort((a, b) => new Date(a.startAt) - new Date(b.startAt))
            );
          } else {
            commit('UPDATE_BOOKING_AVAILABLE_SLOTS_BY_PARAMS_DATA', []);
          }
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });

        commit('UPDATE_BOOKING_MODAL_SELECT_SLOTS_IS_SHOWN_STATUS', false);
      });
  },

  checkBookingWithLamodaCarReturnItemSlots({ commit, state }, params) {
    checkBookingWithLamodaCarReturnItemSlots(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          const result = Object.values(response.data.data);
          if (result.length) {
            commit(
              'UPDATE_BOOKING_AVAILABLE_SLOTS_BY_PARAMS_DATA',
              result.sort((a, b) => new Date(a.startAt) - new Date(b.startAt))
            );
          } else {
            commit('UPDATE_BOOKING_AVAILABLE_SLOTS_BY_PARAMS_DATA', []);
          }
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });

        commit('UPDATE_BOOKING_MODAL_SELECT_SLOTS_IS_SHOWN_STATUS', false);
      });
  },

  createBookingShipmentItem({ commit, dispatch, state }, params) {
    createBookingShipmentItem(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('BOOKING_DATA_IS_UPDATED', true);
          commit('UPDATE_LOADING_SUCCESS', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING_SUCCESS', false);
      });
  },

  createBookingShipmentItemNoLimits({ commit, dispatch, state }, params) {
    createBookingShipmentItemNoLimits(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('BOOKING_DATA_IS_UPDATED', true);
          commit('UPDATE_LOADING_SUCCESS', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING_SUCCESS', false);
      });
  },

  createBookingReturnItem({ commit, dispatch, state }, params) {
    createBookingReturnItem(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
          commit('UPDATE_LOADING_SUCCESS', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING_SUCCESS', false);
      });
  },

  createBookingReturnItemNoLimits({ commit, dispatch, state }, params) {
    createBookingReturnItemNoLimits(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
          commit('UPDATE_LOADING_SUCCESS', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING_SUCCESS', false);
      });
  },

  createBookingInboundItem({ commit, dispatch, state }, params) {
    createBookingInboundItem(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
          commit('UPDATE_LOADING_SUCCESS', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING_SUCCESS', false);
      });
  },

  createBookingInboundItemNoLimits({ commit, dispatch, state }, params) {
    createBookingInboundItemNoLimits(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
          commit('UPDATE_LOADING_SUCCESS', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING_SUCCESS', false);
      });
  },

  createBookingOutboundItem({ commit, dispatch, state }, params) {
    createBookingOutboundItem(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
          commit('UPDATE_LOADING_SUCCESS', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING_SUCCESS', false);
      });
  },

  createBookingOutboundItemNoLimits({ commit, dispatch, state }, params) {
    createBookingOutboundItemNoLimits(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
          commit('UPDATE_LOADING_SUCCESS', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING_SUCCESS', false);
      });
  },

  createBookingLamodaCar({ commit, dispatch, state }, params) {
    createBookingLamodaCar(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('BOOKING_DATA_IS_UPDATED', true);
          commit('UPDATE_LOADING_SUCCESS', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING_SUCCESS', false);
      });
  },

  createSlotSubscription({ commit, dispatch, state }, params) {
    createSlotSubscription(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('BOOKING_DATA_IS_UPDATED', true);
          commit('UPDATE_LOADING_SUCCESS', true);
          commit('UPDATE_BOOKING_MODAL_SELECT_SUBSCRIPTION_SLOTS_IS_SHOWN_STATUS', false);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING_SUCCESS', false);
      });
  },

  updateBookingSupplyWithLamodaCar({ commit, dispatch, state }, params) {
    updateBookingSupplyWithLamodaCar(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('BOOKING_DATA_IS_UPDATED', true);
          commit('UPDATE_LOADING_SUCCESS', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING_SUCCESS', false);
      });
  },
  updateBookingReturnWithLamodaCar({ commit, dispatch, state }, params) {
    updateBookingReturnWithLamodaCar(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('BOOKING_DATA_IS_UPDATED', true);
          commit('UPDATE_LOADING_SUCCESS', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING_SUCCESS', false);
      });
  },
  createBookingReturnLamodaCar({ commit, dispatch, state }, params) {
    createBookingReturnLamodaCar(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('BOOKING_DATA_IS_UPDATED', true);
          commit('UPDATE_LOADING_SUCCESS', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING_SUCCESS', false);
      });
  },

  updateBookingShipmentItem({ commit, dispatch, state }, { id, data }) {
    updateBookingShipmentItem(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
          commit('UPDATE_LOADING_SUCCESS', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING_SUCCESS', false);
      });
  },

  updateBookingShipmentInterrogationItem({ commit, dispatch, state }, { id, data }) {
    updateBookingShipmentInterrogationItem(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
          commit('UPDATE_LOADING_SUCCESS', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING_SUCCESS', false);
      });
  },

  updateBookingReturnItem({ commit, dispatch, state }, { id, data }) {
    updateBookingReturnItem(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
          commit('UPDATE_LOADING_SUCCESS', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING_SUCCESS', false);
      });
  },

  updateBookingReturnInterrogationItem({ commit, dispatch, state }, { id, data }) {
    updateBookingReturnInterrogationItem(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
          commit('UPDATE_LOADING_SUCCESS', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING_SUCCESS', false);
      });
  },

  updateBookingInboundItem({ commit, dispatch, state }, { id, data }) {
    updateBookingInboundItem(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
          commit('UPDATE_LOADING_SUCCESS', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING_SUCCESS', false);
      });
  },

  updateBookingOutboundItem({ commit, dispatch, state }, { id, data }) {
    updateBookingOutboundItem(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
          commit('UPDATE_LOADING_SUCCESS', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING_SUCCESS', false);
      });
  },

  saveBookingItem({ commit, dispatch, state }, { id, data, updateData }) {
    saveBookingItem(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          if (updateData) {
            ElNotification.success({
              message: response.data.message,
              duration: 2000,
            });

            commit('BOOKING_DATA_IS_UPDATED', true);
          }
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  changeBookingStatusOnly({ commit, state }, params) {
    changeBookingStatusOnly(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  changeBookingItemStatus({ commit, dispatch, state }, { id, data }) {
    changeBookingItemStatus(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  changeBookingItemDriverArrivedStatus({ commit, dispatch, state }, { id, data }) {
    changeBookingItemDriverArrivedStatus(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  changeBookingItemDriverReleasedStatus({ commit, dispatch, state }, { id, data }) {
    changeBookingItemDriverReleasedStatus(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  changeBookingItemStatusDocumentsAccepted({ commit, dispatch, state }, { id, data }) {
    changeBookingItemStatusDocumentsAccepted(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  changeBookingItemStatusGateCheckIn({ commit, dispatch, state }, { id, data }) {
    changeBookingItemStatusGateCheckIn(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  changeBookingItemCarProcessingStatus({ commit, dispatch, state }, { id, data }) {
    changeBookingItemCarProcessingStatus(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  changeBookingItemProcessingDoneStatus({ commit, dispatch, state }, { id, data }) {
    changeBookingItemProcessingDoneStatus(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  changeBookingItemCancelByManagerStatus({ commit, dispatch, state }, { id, data }) {
    changeBookingItemCancelByManagerStatus(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  changeBookingItemCarParkingStatus({ commit, dispatch, state }, { id, data }) {
    changeBookingItemCarParkingStatus(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  changeBookingItemDocumentStatus({ commit, dispatch, state }, { id, documentId, data }) {
    changeBookingItemDocumentStatus(id, documentId, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  changeBookingItemDocumentComment({ commit, dispatch, state }, { id, documentId, data }) {
    changeBookingItemDocumentComment(id, documentId, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  setFalseLoading({ commit }, payload) {
    commit('UPDATE_LOADING_SUCCESS', payload);
  },

  getInterrogationBookingList({ commit, state }, params) {
    getInterrogationBookingList(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_INTERROGATION_BOOKING_LIST', response.data.data);
          commit('privilege/UPDATE_BOOKING_INTERROGATION_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch((error) => {
        commit('privilege/UPDATE_BOOKING_INTERROGATION_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  approveInterrogationBookingItem({ commit, dispatch, state }, id) {
    approveInterrogationBookingItem(id)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);

          router.replace({
            name: 'booking',
          });
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  cancelInterrogationBookingItem({ commit, dispatch, state }, id) {
    cancelInterrogationBookingItem(id)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);

          router.replace({
            name: 'booking',
          });
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  cancelBookingItems({ commit, dispatch, state }, data) {
    cancelBookingItems(data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },

  updateFactCorrection({ commit, dispatch, state }, { id, data }) {
    updateFactCorrection(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('BOOKING_DATA_IS_UPDATED', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import { createI18n } from 'vue-i18n';
import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import ElementPlusLocaleRu from 'element-plus/es/locale/lang/ru';
import App from './App.vue';
import router from './router';
import store from './store';
import 'element-plus/dist/index.css';
import '@/assets/styles/index.scss';
import VueTheMask from 'vue-the-mask';
import VueUploadComponent from 'vue-upload-component';
import messages from './i18n/langs';
import { Tabs, Tab } from 'vue3-tabs-component';
import dayjs from 'dayjs';
import { createHead } from '@vueuse/head';

require('dayjs/locale/ru');
dayjs.locale('ru');

const i18n = createI18n({
  locale: 'ru',
  messages,
});

const app = createApp(App);

app
  .component('FileUpload', VueUploadComponent)
  .component('Tabs', Tabs)
  .component('Tab', Tab)
  .use(i18n)
  .use(createHead())
  .use(router)
  .use(store)
  .use(ElementPlus, {
    locale: ElementPlusLocaleRu,
  })
  .use(VueTheMask)
  .mount('#app');

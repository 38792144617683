import { defaultApiInstance } from '@/compositions/api';

export function setPartnersDirectionRestriction(data) {
  return defaultApiInstance.put(`v1/directionType/${data.id}/restrictionSetting`, data.payload);
}
export function setRestrictionByName(data) {
  return defaultApiInstance.put(`v1/restrictionSetting/`, data);
}
export function getBookingTimeRestriction() {
  return defaultApiInstance.get(`v1/restrictionSetting/limitTermsSupplies/`);
}

export function getLimitedReturns() {
  return defaultApiInstance.get(`v1/restrictionSetting/limitTermsReturns/`);
}
export function getRestrictions() {
  return defaultApiInstance.get(`v1/restrictionSetting/`);
}

export function getTermLateReturn() {
  return defaultApiInstance.get(`v1/TermLateReturn`);
}

export function setTermLateReturn(data) {
  return defaultApiInstance.post(`v1/TermLateReturn`, data.payload);
}

export function removeLateReturn(id) {
  return defaultApiInstance.delete(`v1/TermLateReturn/${id}`);
}

export function updateLateReturn(data) {
  return defaultApiInstance.put(`v1/TermLateReturn/${data.id}`, data.payload);
}

<script>
/* eslint-disable */
import { computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
import Auth from '@/components/layouts/auth/Auth.vue';
import Main from '@/components/layouts/main/Main.vue';
import Unwrapped from '@/components/layouts/unwrapped/Unwrapped'
import Empty from '@/components/layouts/empty/Empty.vue';
import Forbidden from '@/components/layouts/forbidden/Forbidden.vue';
/* eslint-enable */

export default {
  components: { Auth, Main, Unwrapped, Empty, Forbidden },
  setup() {
    const pageMetaData = reactive({
      title: '',
      description: '',
    })
    useHead({
      title: computed(() => pageMetaData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => pageMetaData.description),
        },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: computed(() => pageMetaData.title) },
        { property: 'og:description', content: computed(() => pageMetaData.description) },
        { property: 'og:site_name', content: 'Lamoda GM' },
        { property: 'og:image', content: `${window.location.protocol}//${window.location.host}/img/logo.svg` },
      ],
      link: [{
        rel: 'icon',
        type: 'image/x-icon',
        href: '/favicon.ico'
      }],
    })

    return {
      pageMetaData
    }
  },
  data() {
    return {
      layout: this.$route.meta.layout,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        this.layout = to.meta.layout;
        this.pageMetaData.title = to.meta?.title ? `${to.meta.title} | Lamoda GM` : 'Lamoda GM';
        this.pageMetaData.description = to.meta?.description ?? 'Lamoda GM';
      }
    },
  },
};
</script>

<template>
  <component :is="layout">
    <slot />
  </component>
</template>

/* eslint-disable */
import { defaultApiInstance } from '@/compositions/api';

export function getInboundList(data) {
  return defaultApiInstance.get(`v1/inbound/`, {
    params: {
      storageId: data.storageId,
      offset: data.offset,
      limit: data.limit,
      active: data.active,
      directionId: data.directionId,
      name: data.name
    },
  });
}

export function createInboundItem(data) {
  return defaultApiInstance.post(`v1/inbound/`, JSON.stringify(data));
}

export function updateInboundItem(id, data) {
  return defaultApiInstance.put(`v1/inbound/${id}`, JSON.stringify(data));
}

export function deactivateInboundItem(id, data) {
  return defaultApiInstance.patch(`v1/inbound/${id}/deactivate`, JSON.stringify(data));
}

/* eslint-disable */
import { defaultApiInstance } from '@/compositions/api';

export function getReturnList(params) {
  return defaultApiInstance.get(`v1/return`, { params });
}

export function getReturnForBookingList(params) {
  return defaultApiInstance.get(`v1/return/getForBooking`, { params });
}

export function getReturnItem(id) {
  return defaultApiInstance.get(`v1/return/${id}`);
}

export function createReturnItem(data) {
  return defaultApiInstance.post(`v1/return`, JSON.stringify(data));
}

export function updateReturnItem(id, data) {
  return defaultApiInstance.put(`v1/return/${id}`, JSON.stringify(data));
}

export function deactivateReturnItem(id, data) {
  return defaultApiInstance.patch(`v1/return/${id}/deactivate`, JSON.stringify(data));
}

export function getReturnExport(params) {
  return defaultApiInstance.get(`v1/return/export`, { params });
}

export function postReturnImport(data) {
  return defaultApiInstance.post(`v1/return/import`, JSON.stringify(data));
}

export function getOtherStorageReturn(params) {
  return defaultApiInstance.get(`v1/return/getOtherStorageReturn`, { params });
}

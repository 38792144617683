<script>
import Menu from '@/components/blocks/menu/Menu';
import Header from '@/components/blocks/header/Header';

export default {
  components: {
    Menu,
    Header,
  },
};
</script>

<template>
  <div class="page --has-sidebar">
    <Header />
    <Menu />
    <div class="content">
      <div class="container">
        <h1>Отказано в доступе</h1>
        <p>У Вас недостаточно прав для просмотра этой страницы</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>

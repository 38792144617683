import {
  getGates,
  getGatesDirection,
  getGateStatus,
  getGateBooking,
  getGate,
  gateCallTime,
  setActivateGate,
  setDeactivateGate,
  updateGateStatus,
} from '@/methods/gates';

import { ElNotification } from 'element-plus';
import store from '@/store';
import router from '@/router';
import { getDenyPrivilegeObject, getErrorMessage } from '@/compositions/helpers/helpers';

const state = {
  gatesList: [],
  gateDirectionList: [],
  currentGate: [],
  bookingWithTime: [],
  bookingAwaiting: [],
  bookingWithGate: [],
  gateIsUpdated: false,
  currentBuilding: '',
  currentBuildingName: '',
  error: false,
};

const getters = {
  getGatesList(state) {
    return state.gatesList;
  },
  getCurrentGate(state) {
    return state.currentGate;
  },
  getGateDirectionList(state) {
    return state.gateDirectionList;
  },
  getBookingAwaiting(state) {
    return state.bookingAwaiting;
  },
  bookingWithTime(state) {
    return state.bookingWithTime;
  },
  getbookingWithGate(state) {
    return state.bookingWithGate;
  },
  currentbuilding(state) {
    return state.currentBuilding;
  },
};

const mutations = {
  UPDATE_GATES(state, data) {
    state.gatesList = data;
  },
  UPDATE_CURRENT_GATE(state, data) {
    state.currentGate = data;
  },
  UPDATE_GATES_DIRECTION(state, data) {
    state.gateDirectionList = data;
  },
  UPDATE_BOOKING_AWAITING(state, data) {
    state.bookingAwaiting = data;
  },
  UPDATE_BOOKING_WITH_TIME(state, data) {
    state.bookingWithTime = data;
  },
  UPDATE_BOOKING_WITH_GATE(state, data) {
    state.bookingWithGate = data;
  },
  SET_ERROR(state, data) {
    state.error = data;
  },
  SET_UPDATE_GATE(state, data) {
    state.gateIsUpdated = data;
  },
  SET_CURRENT_BUILDING(state, data) {
    state.currentBuilding = data;
  },
  SET_CURRENT_BUILDING_NAME(state, data) {
    state.currentBuildingName = data;
  },
};

const actions = {
  getGates({ commit, state }, params) {
    getGates(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_GATES', response.data.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          store.commit('auth/UPDATE_CONTENT_AVAILABILITY', false);

          if (!router.currentRoute.value.query?.forbidden) {
            router
              .replace({
                name: router.currentRoute.value.name,
                query: { ...router.currentRoute.value.query, forbidden: true },
              })
              .then(() => {
                location.reload();
              });
          }
        }
      });
  },

  getGate({ commit, state }, params) {
    getGate(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit(
            'UPDATE_CURRENT_GATE',
            response.data.data.sort((a, b) => a.id - b.id)
          );
          commit('privilege/UPDATE_SETTINGS_BUILDING_GATE_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch(() => {
        commit('privilege/UPDATE_SETTINGS_BUILDING_GATE_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
      });
  },

  getGatesDirection({ commit, state }, params) {
    getGatesDirection(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_GATES_DIRECTION', response.data.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          store.commit('auth/UPDATE_CONTENT_AVAILABILITY', false);

          if (!router.currentRoute.value.query?.forbidden) {
            router
              .replace({
                name: router.currentRoute.value.name,
                query: { ...router.currentRoute.value.query, forbidden: true },
              })
              .then(() => {
                location.reload();
              });
          }
        }
      });
  },

  getGateBooking({ commit, state }, params) {
    getGateBooking(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          const awaiting = [];
          const arrived = [];
          const bookingWithGate = [];
          //распределяем поставки на тех, у кого есть время прибытия, у кого нет времени и у кого определены ворота
          response.data.data.forEach((elem) => {
            if (!elem.actualArrivalTime && elem.gate === null) {
              awaiting.push(elem);
            } else if (elem.actualArrivalTime && elem.gateCallTime === null) {
              arrived.push(elem);

              if (elem?.gate?.id) {
                bookingWithGate.push(elem);
              }
            } else {
              bookingWithGate.push(elem);
            }
          });
          commit('UPDATE_BOOKING_AWAITING', awaiting);
          commit('UPDATE_BOOKING_WITH_TIME', arrived);
          commit('UPDATE_BOOKING_WITH_GATE', bookingWithGate);
          commit('SET_UPDATE_GATE', false);
          return true;
        }
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          ElNotification.error({
            message: 'Ошибка загрузки броней, перезагрузите страницу',
            duration: 2000,
          });
        }
        if (error.response?.status === 403) {
          store.commit('auth/UPDATE_CONTENT_AVAILABILITY', false);

          if (!router.currentRoute.value.query?.forbidden) {
            router
              .replace({
                name: router.currentRoute.value.name,
                query: { ...router.currentRoute.value.query, forbidden: true },
              })
              .then(() => {
                location.reload();
              });
          }
        }
      });
  },

  gateCallTime({ commit, state }, params) {
    gateCallTime(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('SET_UPDATE_GATE', true);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('SET_ERROR', true);
        //location.reload();
        return 'error';
      });
  },

  setActivateGate({ commit, state }, params) {
    setActivateGate(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('SET_ERROR', false);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('SET_ERROR', true);
        return 'error';
      });
  },

  setDeactivateGate({ commit, state }, params) {
    setDeactivateGate(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('SET_ERROR', false);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('SET_ERROR', true);
        return 'error';
      });
  },

  updateGateStatus({ commit, state }, params) {
    updateGateStatus(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          commit('SET_ERROR', false);
          getGate(params.dataId).then((res) => {
            if ('success' in res.data && res.data.success) {
              commit(
                'UPDATE_CURRENT_GATE',
                res.data.data.sort((a, b) => a.id - b.id)
              );
            }
          });
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('SET_ERROR', true);
        return 'error';
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512.853 512.853"
    style="enable-background:new 0 0 512.853 512.853;"
    width="24"
    height="24"
    xml:space="preserve"
  >
    <g transform="translate(1 1)">
      <g>
        <g>
          <path
            fill="white"
            d="M504.173,193.133l-74.24-81.92c-5.973-5.973-13.653-9.387-21.333-9.387h-85.333v-17.92
				c0-18.773-14.507-33.28-32.427-33.28H32.28C13.507,50.627-1,65.133-1,83.907V289.56v17.067v72.533
				c0,16.213,13.653,29.867,29.867,29.867h13.403c4.075,29.155,28.753,51.2,59.131,51.2c30.378,0,55.056-22.045,59.131-51.2H306.2
				h17.067h26.203c4.075,29.155,28.753,51.2,59.131,51.2s55.056-22.045,59.131-51.2H511h0.853V212.76
				C511.853,205.08,509.293,198.253,504.173,193.133z M490.52,204.227c2.56,1.707,3.413,5.12,3.413,7.68v26.453h-128v-85.333h77.653
				L490.52,204.227z M16.067,229.827h119.467c5.12,0,8.533-3.413,8.533-8.533s-3.413-8.533-8.533-8.533H16.067v-25.6H101.4
				c5.12,0,8.533-3.413,8.533-8.533c0-5.12-3.413-8.533-8.533-8.533H16.067V83.907c0-9.387,6.827-16.213,16.213-16.213h257.707
				c9.387,0,16.213,7.68,16.213,16.213v17.92V289.56H16.067V229.827z M101.4,443.16c-23.893,0-42.667-18.773-42.667-42.667
				c0-23.893,18.773-42.667,42.667-42.667c23.893,0,42.667,18.773,42.667,42.667C144.067,424.387,125.293,443.16,101.4,443.16z
				 M306.2,391.96H160.531c-3.821-27.339-25.761-48.416-53.528-50.936c-0.203-0.019-0.409-0.029-0.613-0.046
				c-0.699-0.056-1.397-0.114-2.103-0.147c-0.957-0.046-1.919-0.072-2.887-0.072s-1.93,0.026-2.887,0.072
				c-0.706,0.033-1.404,0.09-2.103,0.147c-0.204,0.017-0.41,0.027-0.613,0.046c-27.767,2.52-49.707,23.597-53.528,50.936H28.867
				c-6.827,0-12.8-5.973-12.8-12.8v-72.533H306.2V391.96z M408.6,443.16c-23.893,0-42.667-18.773-42.667-42.667
				c0-23.893,18.773-42.667,42.667-42.667c23.893,0,42.667,18.773,42.667,42.667C451.267,424.387,432.493,443.16,408.6,443.16z
				 M467.731,391.96c-3.821-27.339-25.761-48.416-53.528-50.936c-0.203-0.019-0.409-0.029-0.613-0.046
				c-0.699-0.056-1.397-0.114-2.103-0.147c-0.957-0.046-1.919-0.072-2.887-0.072s-1.93,0.026-2.887,0.072
				c-0.706,0.033-1.404,0.09-2.103,0.147c-0.204,0.017-0.41,0.027-0.613,0.046c-27.767,2.52-49.707,23.597-53.528,50.936h-26.203
				v-85.333V289.56V118.893H408.6c2.56,0,5.973,1.707,8.533,3.413l12.231,13.653h-65.991c-7.68,0-14.507,6.827-14.507,14.507v90.453
				c0,7.68,6.827,14.507,14.507,14.507h130.56v42.667h-42.667c-5.12,0-8.533,3.413-8.533,8.533s3.413,8.533,8.533,8.533h42.667v76.8
				H467.731z"
          />
          <path
            d="M101.4,383.427c-9.387,0-17.067,7.68-17.067,17.067s7.68,17.067,17.067,17.067c9.387,0,17.067-7.68,17.067-17.067
				S110.787,383.427,101.4,383.427z"
          />
          <path
            d="M408.6,383.427c-9.387,0-17.067,7.68-17.067,17.067s7.68,17.067,17.067,17.067s17.067-7.68,17.067-17.067
				S417.987,383.427,408.6,383.427z"
          />
        </g>
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
</template>

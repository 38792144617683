/* eslint-disable */
import { defaultApiInstance } from '@/compositions/api';

export function getPartners(params) {
  return defaultApiInstance.get(`v1/partners`, { params });
}

export function getPartnerStatistics(params) {
  return defaultApiInstance.get(`v1/partner/${params.partnerId}/statistics`, {
    params: {
      storageId: params.storageId,
    },
  });
}

export function getCurrentPartner(partnerId) {
  return defaultApiInstance.get(`v1/partner/${partnerId}`);
}

export function getPartnerCooperationType(partnerId) {
  return defaultApiInstance.get(`v1/partner/${partnerId}/cooperationType`);
}

export function getPartnerStorages(partnerId) {
  return defaultApiInstance.get(`v1/partner/${partnerId}/partnerStorage`);
}

export function getPartnerRatingMatrix(partnerId) {
  return defaultApiInstance.get(`v1/partner/${partnerId}/rating/matrix`);
}

export function updatePartner(data) {
  return defaultApiInstance.put(`v1/partnerSetting/${data.id}`, data.payload);
}

export function createPartnerStorageItem(data) {
  return defaultApiInstance.post(`v1/partner/${data.partnerId}/partnerStorage/create`, JSON.stringify(data.payload));
}

export function getResponsibleUsers(params) {
  return defaultApiInstance.get(`v1/responsible`, { params });
}

export function getPartnerExport() {
  return defaultApiInstance.get(`v1/partner/settings/export`);
}

export function getPartnerUserList(partnerId, params) {
  return defaultApiInstance.get(`v1/partner/${partnerId}/user`, { params });
}

export function createPartnerUserItem(data) {
  return defaultApiInstance.post(`v1/partner/${data.partnerId}/user`, JSON.stringify(data.payload));
}

export function updatePartnerUserItem(data) {
  return defaultApiInstance.put(`v1/partner/${data.partnerId}/user/${data.userId}`, JSON.stringify(data.payload));
}

export function deletePartnerUserItem(data) {
  return defaultApiInstance.delete(`v1/partner/${data.partnerId}/user/${data.userId}`);
}

import { defaultApiInstance } from '@/compositions/api';

export function getSmiOtList() {
  return defaultApiInstance.get(`v1/smi/ot`);
}

export function getSmiOtItem(id) {
  return defaultApiInstance.get(`v1/smi/ot/${id}`);
}

export function createSmiOtItem(data) {
  return defaultApiInstance.post(`v1/smi/ot`, JSON.stringify(data));
}

export function updateSmiOtItem(id, data) {
  return defaultApiInstance.put(`v1/smi/ot/${id}`, JSON.stringify(data));
}

export function deleteSmiOtItem(id) {
  return defaultApiInstance.delete(`v1/smi/ot/${id}`);
}

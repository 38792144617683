/* eslint-disable */
import { defaultApiInstance } from '@/compositions/api';

export function getWeekDays() {
  return defaultApiInstance.get(`v1/collection/weekDays`);
}

export function getRestrictionList() {
  return defaultApiInstance.get(`v1/collection/restrictionList`);
}

export function getReturnStatusList() {
  return defaultApiInstance.get(`v1/collection/returnStatus`);
}

export function getShipmentStatusList() {
  return defaultApiInstance.get(`v1/collection/shipmentStatus`);
}

export function getBoxStatusList() {
  return defaultApiInstance.get(`v1/collection/boxStatus`);
}

export function getBoxSizeList() {
  return defaultApiInstance.get(`v1/collection/boxSize`);
}

export function getBookingStatusList() {
  return defaultApiInstance.get(`v1/collection/bookingStatus`);
}

export function getDirectionTypesList() {
  return defaultApiInstance.get(`v1/directionTypes`);
}

export function getDocumentsStatusAtReceptionList() {
  return defaultApiInstance.get(`v1/collection/documentsStatusAtReception`);
}

export function getDocumentsStatusOnResultList() {
  return defaultApiInstance.get(`v1/collection/documentsStatusOnResult`);
}

export function getReturnTypeList() {
  return defaultApiInstance.get(`v1/collection/returnTypeList`);
}

export function getParishList() {
  return defaultApiInstance.get(`v1/collection/parishList`);
}

export function getReasonsForNotShippingOnTimeList() {
  return defaultApiInstance.get(`v1/collection/reasonsForNotShippingOnTimeList`);
}

export function getReasonsForNotUnloadOnTime() {
  return defaultApiInstance.get(`v1/collection/reasonForNotUnloadOnTime`);
}

export function getHoldingTypes() {
  return defaultApiInstance.get(`v1/collection/holdingTypes`);
}

export function getIncidentSubjectList() {
  return defaultApiInstance.get(`v1/collection/incidentSubject`);
}

export function getIncidentStatusList() {
  return defaultApiInstance.get(`v1/collection/incidentStatuses`);
}

export function getCarOrderStatusList() {
  return defaultApiInstance.get(`v1/collection/carOrderStatus`);
}

export function getWorkGroupList() {
  return defaultApiInstance.get(`v1/collection/workGroup`);
}

export function getChangeLogObjectList() {
  return defaultApiInstance.get(`v1/collection/changeLogObject`);
}

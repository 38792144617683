<script>
import Header from '@/components/blocks/header/Header';

export default {
  components: {
    Header,
  },
};
</script>

<template>
  <div class="page --full">
    <Header />
    <div class="content">
      <div class="container">
        <router-view />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>

/* eslint-disable */
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { computed, ref } from "vue";

export function menu() {
  /* eslint-enable */
  const store = useStore();
  const router = useRouter();
  const menuActive = ref(false);

  const toggleMenu = () => {
    menuActive.value = !menuActive.value;
  }

  const clickOnRoute = (item) => {
    store.commit('menu/UPDATE_NAVBAR_ROUTES', item.route);
    router.push(item.route).then(() => {
      menuActive.value = false;
    });
  };

  const setStartRoute = (route) => {
    store.commit('menu/UPDATE_NAVBAR_ROUTES', route);
  }

  const routes = computed(() => store.state.menu.routes);

  return {
    toggleMenu,
    clickOnRoute,
    setStartRoute,
    routes,
    menuActive
  };
}

/* eslint-disable */
import { ElNotification } from 'element-plus';
import { defaultApiInstance } from '@/compositions/api';

export function getStorageList() {
  return defaultApiInstance.get(`v1/storage`);
}

export function getStorageItem(id) {
  return defaultApiInstance.get(`v1/storage/${id}`);
}

export function getStorageBreakTime(storageId) {
  if (storageId === null || typeof storageId === 'undefined' || isNaN(storageId)) {
    ElNotification.error({
      message: 'Так как склад не выбран, данный раздел пуст',
      duration: 2000,
    });
    return;
  }
  return defaultApiInstance.get(`v1/storage/${storageId}/breakTime`);
}

export function getStorageDirection(storageId) {
  if (storageId === null || typeof storageId === 'undefined' || isNaN(storageId)) {
    ElNotification.error({
      message: 'Так как склад не выбран, данный раздел пуст',
      duration: 2000,
    });
    return;
  }
  return defaultApiInstance.get(`v1/storage/${storageId}/direction`);
}

export function getStorageInboundDirections(storageId) {
  if (storageId === null || typeof storageId === 'undefined' || isNaN(storageId)) {
    ElNotification.error({
      message: 'Так как склад не выбран, данный раздел пуст',
      duration: 2000,
    });
    return;
  }
  return defaultApiInstance.get(`v1/storage/${storageId}/inboundDirections`);
}

export function getStorageOutboundDirections(storageId) {
  if (storageId === null || typeof storageId === 'undefined' || isNaN(storageId)) {
    ElNotification.error({
      message: 'Так как склад не выбран, данный раздел пуст',
      duration: 2000,
    });
    return;
  }
  return defaultApiInstance.get(`v1/storage/${storageId}/outboundDirections`);
}

export function getStorageGate(storageId) {
  if (storageId === null || typeof storageId === 'undefined' || isNaN(storageId)) {
    ElNotification.error({
      message: 'Так как склад не выбран, данный раздел пуст',
      duration: 2000,
    });
    return;
  }
  return defaultApiInstance.get(`v1/storage/${storageId}/gate`);
}

export function getStorageSettings(storageId) {
  if (storageId === null || typeof storageId === 'undefined' || isNaN(storageId)) {
    ElNotification.error({
      message: 'Так как склад не выбран, данный раздел пуст',
      duration: 2000,
    });
    return;
  }
  return defaultApiInstance.get(`v1/storage/${storageId}/settings`);
}

export function getStorageRestrictionType(storageId) {
  if (storageId === null || typeof storageId === 'undefined' || isNaN(storageId)) {
    ElNotification.error({
      message: 'Так как склад не выбран, данный раздел пуст',
      duration: 2000,
    });
    return;
  }
  return defaultApiInstance.get(`v1/storage/${storageId}/restriction`);
}

export function getStorageRestrictionTypeCar(storageId) {
  if (storageId === null || typeof storageId === 'undefined' || isNaN(storageId)) {
    ElNotification.error({
      message: 'Так как склад не выбран, данный раздел пуст',
      duration: 2000,
    });
    return;
  }
  return defaultApiInstance.get(`v1/storage/${storageId}/restriction/car`);
}

export function getStorageRestrictionTypeCarExtended(restrictionId) {
  return defaultApiInstance.get(`v1/restriction/${restrictionId}/car/extended`);
}

export function getStorageRestrictionTypeScheduleRange(storageId) {
  if (storageId === null || typeof storageId === 'undefined' || isNaN(storageId)) {
    ElNotification.error({
      message: 'Так как склад не выбран, данный раздел пуст',
      duration: 2000,
    });
    return;
  }
  return defaultApiInstance.get(`v1/storage/${storageId}/restriction/range`);
}

export function getStorageWorkTime(storageId) {
  if (storageId === null || typeof storageId === 'undefined' || isNaN(storageId)) {
    ElNotification.error({
      message: 'Так как склад не выбран, данный раздел пуст',
      duration: 2000,
    });
    return;
  }
  return defaultApiInstance.get(`v1/storage/${storageId}/workTime`);
}

export function getLastOldScheduleDate(storageId) {
  if (storageId === null || typeof storageId === 'undefined' || isNaN(storageId)) {
    ElNotification.error({
      message: 'Так как склад не выбран, данный раздел пуст',
      duration: 2000,
    });
    return;
  }
  return defaultApiInstance.get(`v1/storage/${storageId}/lastOldScheduleDate`);
}

export function getStorageSlotList(storageId) {
  if (storageId === null || typeof storageId === 'undefined' || isNaN(storageId)) {
    ElNotification.error({
      message: 'Так как склад не выбран, данный раздел пуст',
      duration: 2000,
    });
    return;
  }
  return defaultApiInstance.get(`v1/storage/${storageId}/slot`);
}

export function getStorageBuildings(storageId) {
  if (storageId === null || typeof storageId === 'undefined' || isNaN(storageId)) {
    ElNotification.error({
      message: 'Так как склад не выбран, данный раздел пуст',
      duration: 2000,
    });
    return;
  }
  return defaultApiInstance.get(`v1/storage/${storageId}/building`);
}

export function getDirectionLastSlot(id) {
  return defaultApiInstance.get(`v1/direction/${id}/lastSlot`);
}

export function createStorageItem(data) {
  return defaultApiInstance.post(`v1/storage/create`, JSON.stringify(data));
}

export function createStorageDirection(storageId, data) {
  return defaultApiInstance.post(`v1/storage/${storageId}/direction`, JSON.stringify(data));
}

export function createStorageGate(storageId, data) {
  return defaultApiInstance.post(`v1/storage/${storageId}/gate`, JSON.stringify(data));
}

export function createStorageRestrictionType(storageId, data) {
  return defaultApiInstance.post(`v1/restriction`, JSON.stringify(data));
}

export function createStorageRestrictionTypeCar(storageId, data) {
  return defaultApiInstance.post(`v1/storage/${storageId}/restriction/car`, JSON.stringify(data));
}

export function createStorageRestrictionTypeCarExtended(restrictionId, data) {
  return defaultApiInstance.post(`v1/restriction/${restrictionId}/car/extended/create`, JSON.stringify(data));
}

export function createStorageRestrictionTypeScheduleRange(storageId, data) {
  return defaultApiInstance.post(`v1/storage/${storageId}/restriction/range`, JSON.stringify(data));
}

export function createStorageWorkTime(storageId, data) {
  return defaultApiInstance.post(`v1/storage/${storageId}/workTime`, JSON.stringify(data));
}

export function createStorageBuilding(storageId, data) {
  return defaultApiInstance.post(`v1/storage/${storageId}/building`, JSON.stringify(data));
}

export function updateStorageItem(id, data) {
  return defaultApiInstance.put(`v1/storage/${id}/update`, JSON.stringify(data));
}

export function updateStorageRestrictionType(data) {
  return defaultApiInstance.put(`v1/restriction`, JSON.stringify(data));
}

export function updateStorageRestrictionTypeCar(id, data) {
  return defaultApiInstance.put(`v1/restriction/car/${id}`, JSON.stringify(data));
}

export function updateStorageRestrictionTypeCarExtended(id, data) {
  return defaultApiInstance.put(`v1/restriction/car/extended/${id}/update`, JSON.stringify(data));
}

export function updateStorageRestrictionTypeScheduleRange(id, data) {
  return defaultApiInstance.put(`v1/restriction/range/${id}`, JSON.stringify(data));
}

export function updateStorageSettings(storageId, data) {
  return defaultApiInstance.patch(`v1/storage/${storageId}/settings`, JSON.stringify(data));
}

export function updateStorageSlotSizeByDirection(data) {
  return defaultApiInstance.post(`v1/slotSizeByDirection`, JSON.stringify(data));
}

export function updateStorageBuilding(id, data) {
  return defaultApiInstance.put(`v1/building/${id}`, JSON.stringify(data));
}

export function updateStorageDirection(storageId, data) {
  return defaultApiInstance.put(`v1/storage/${storageId}/direction`, JSON.stringify(data));
}

export function updateStorageWorkTime(storageId, data) {
  return defaultApiInstance.put(`v1/storage/${storageId}/workTime`, JSON.stringify(data));
}

export function updateStorageGate(id, data) {
  return defaultApiInstance.put(`v1/gate/${id}`, JSON.stringify(data));
}

export function updateWorkTimeName(data) {
  return defaultApiInstance.put(`v1/workTimeNameUpdate`, JSON.stringify(data));
}

export function activateStorageGate(id, data) {
  return defaultApiInstance.put(`v1/gate/${id}/activate`, JSON.stringify(data));
}

export function deactivateStorageGate(id, data) {
  return defaultApiInstance.put(`v1/gate/${id}/deactivate`, JSON.stringify(data));
}

export function deleteStorageRestrictionTypeCarExtended(id) {
  return defaultApiInstance.delete(`v1/restriction/car/extended/${id}/delete`);
}

export function activateStorageItem(id) {
  return defaultApiInstance.put(`v1/storage/${id}/activate`);
}

export function deactivateStorageItem(id) {
  return defaultApiInstance.put(`v1/storage/${id}/deactivate`);
}

export function deleteStorageBuilding(id) {
  return defaultApiInstance.delete(`v1/building/${id}`);
}

export function deactivateStorageBuilding(id) {
  return defaultApiInstance.put(`v1/building/${id}/deactivate`);
}

export function getDocumentTypes() {
  return defaultApiInstance.get(`v1/documentType`);
}

export function createDocumentType(data) {
  return defaultApiInstance.post(`v1/documentType/create`, JSON.stringify(data));
}

export function updateDocumentType(id, data) {
  return defaultApiInstance.put(`v1/documentType/${id}/update`, JSON.stringify(data));
}

export function activateDocumentType(id) {
  return defaultApiInstance.patch(`v1/documentType/${id}/activate`);
}

export function deactivateDocumentType(id) {
  return defaultApiInstance.patch(`v1/documentType/${id}/deactivate`);
}

/* eslint-disable */
import { defaultApiInstance } from '@/compositions/api';

export function getCooperationTypes() {
  return defaultApiInstance.get(`v1/cooperationType`);
}

export function createCooperationType(data) {
  return defaultApiInstance.post(`v1/cooperationType/create`, JSON.stringify(data));
}

export function updateCooperationType(id, data) {
  return defaultApiInstance.put(`v1/cooperationType/${id}/update`, JSON.stringify(data));
}

export function activateCooperationType(id) {
  return defaultApiInstance.put(`v1/cooperationType/${id}/activate`);
}

export function deactivateCooperationType(id) {
  return defaultApiInstance.put(`v1/cooperationType/${id}/deactivate`);
}

export function getMyCooperationTypes() {
  return defaultApiInstance.get(`v1/my/cooperationType`);
}

import {
  getCarOrders,
  getShipmentHolds,
  getReturnHolds,
  confirmSingleCarHold,
  confirmSmallDeliveryHolds,
  updateDriverData,
  carHoldChancel,
  getTableExport,
} from '@/methods/carRegistry';
import { ElNotification } from 'element-plus';
import { formatDate, getDenyPrivilegeObject, getErrorMessage } from '@/compositions/helpers/helpers';

const state = {
  carsList: [],
  isLoading: false,
  shipmentHolds: [],
  returnHolds: [],
  filters: {
    dateStart: formatDate(new Date()),
    'Статус заявки': ['Заявка', 'Ожидает слот', 'В обработке транспортом', 'Подтверждена'],
  },
};

const getters = {
  getOrdersList(state) {
    return state.carsList;
  },
  getLoading(state) {
    return state.isLoading;
  },
  getShipmentHolds(state) {
    return state.shipmentHolds;
  },
  getReturnHolds(state) {
    return state.returnHolds;
  },
};

const mutations = {
  UPDATE_ORDERS_LIST(state, data) {
    state.carsList = data;
  },
  UPDATE_LOADING(state, data) {
    state.isLoading = data;
  },
  UPDATE_SHIPMENT_HOLDS(state, data) {
    state.shipmentHolds = data;
  },
  UPDATE_RETURN_HOLDS(state, data) {
    state.returnHolds = data;
  },
  UPDATE_FILTERS(state, data) {
    state.filters = data;
  },
};

const actions = {
  carHoldChancel({ commit, state }, payload) {
    commit('UPDATE_LOADING', true);
    carHoldChancel(payload)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: 'Заявка отменена',
            duration: 2000,
          });
          location.reload();
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },
  getCarOrders({ commit, state }, payload) {
    commit('UPDATE_LOADING', true);
    getCarOrders(payload)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_ORDERS_LIST', response.data.data);
          commit('privilege/UPDATE_CAR_REGISTRY_PRIVILEGE', response.data.privilege, { root: true });
        }
        commit('UPDATE_LOADING', false);
      })
      .catch(() => {
        commit('privilege/UPDATE_CAR_REGISTRY_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
        commit('UPDATE_LOADING', false);
      });
  },
  getShipmentHolds({ commit, state }, payload) {
    getShipmentHolds(payload)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_SHIPMENT_HOLDS', response.data);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },
  getReturnHolds({ commit, state }, payload) {
    getReturnHolds(payload)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_RETURN_HOLDS', response.data);
        }
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },
  confirmSingleCarHold({ commit, state }, payload) {
    commit('UPDATE_LOADING', true);
    confirmSingleCarHold(payload.payload)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          getCarOrders(payload.pageSettings)
            .then((response) => {
              if ('success' in response.data && response.data.success) {
                commit('UPDATE_ORDERS_LIST', response.data.data);
                commit('privilege/UPDATE_CAR_REGISTRY_PRIVILEGE', response.data.privilege, { root: true });
              }
            })
            .catch(() => {
              commit('privilege/UPDATE_CAR_REGISTRY_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
            });
        }
      })
      .then(() => {
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },
  confirmSmallDeliveryHolds({ commit, state }, payload) {
    commit('UPDATE_LOADING', true);
    confirmSmallDeliveryHolds(payload.payload)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          getCarOrders(payload.pageSettings)
            .then((response) => {
              if ('success' in response.data && response.data.success) {
                commit('UPDATE_ORDERS_LIST', response.data.data);
                commit('privilege/UPDATE_CAR_REGISTRY_PRIVILEGE', response.data.privilege, { root: true });
              }
            })
            .catch(() => {
              commit('privilege/UPDATE_CAR_REGISTRY_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
            });
        }
      })
      .then(() => {
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },
  updateDriverData({ commit, state }, payload) {
    commit('UPDATE_LOADING', true);
    updateDriverData(payload.payload)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });
          getCarOrders(payload.pageSettings)
            .then((response) => {
              if ('success' in response.data && response.data.success) {
                commit('UPDATE_ORDERS_LIST', response.data.data);
                commit('privilege/UPDATE_CAR_REGISTRY_PRIVILEGE', response.data.privilege, { root: true });
              }
            })
            .catch(() => {
              commit('privilege/UPDATE_CAR_REGISTRY_PRIVILEGE', getDenyPrivilegeObject(), { root: true });
            });
        }
      })
      .then(() => {
        commit('UPDATE_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_LOADING', false);
      });
  },
  getTableExport({ commit, state }, params) {
    getTableExport(params).then((response) => {
      const downloadLink = document.createElement('a');
      const blob = new Blob(['\ufeff', response.data]);

      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `car-registry-${formatDate(new Date())}.csv`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

/* eslint-disable */
import { defaultApiInstance } from '@/compositions/api';

export function createBreakTimeItem(data) {
  return defaultApiInstance.post(`v1/breakTime`, JSON.stringify(data));
}

export function deleteBreakTimeItem(params) {
  return defaultApiInstance.delete(`v1/breakTime`, { data: JSON.stringify(params) });
}

/* eslint-disable */
import { getOutboundList, createOutboundItem, updateOutboundItem, deactivateOutboundItem } from '@/methods/outbound';
import { ElNotification } from 'element-plus';
import router from '@/router';
import store from '@/store';
import { getErrorMessage } from '@/compositions/helpers/helpers';

const state = {
  list: [],
  dataIsUpdated: false,
  isLoading: false,
  filters: {
    'Статус маршрута': 'Активный',
  },
};

const getters = {
  getOutboundList(state) {
    return state.list;
  },
};

const mutations = {
  UPDATE_OUTBOUND_LIST(state, data) {
    state.list = data;

    if (state.list.data.length) {
      state.list.data = state.list.data.sort((a, b) => (a.active < b.active ? 1 : a.active > b.active ? -1 : 0));
    }
  },
  OUTBOUND_DATA_IS_UPDATED(state, data) {
    state.dataIsUpdated = data;
  },
  UPDATE_IS_LOADING(state, data) {
    state.isLoading = data;
  },
  UPDATE_FILTERS(state, data) {
    state.filters = data;
  },
};

const actions = {
  getOutboundList({ commit, state }, params) {
    getOutboundList(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          commit('UPDATE_OUTBOUND_LIST', response.data.data);
          commit('privilege/UPDATE_OUTBOUND_PRIVILEGE', response.data.privilege, { root: true });
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          store.commit('auth/UPDATE_CONTENT_AVAILABILITY', false);

          if (!router.currentRoute.value.query?.forbidden) {
            router
              .replace({
                name: router.currentRoute.value.name,
                query: { ...router.currentRoute.value.query, forbidden: true },
              })
              .then(() => {
                location.reload();
              });
          }
        }
      });
  },

  createOutboundItem({ commit, dispatch, state }, params) {
    commit('UPDATE_IS_LOADING', true);
    createOutboundItem(params)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          const successArr = response.data.data.successDates;
          const failedArr = response.data.data.failedDates;
          const successBlock = successArr.map((elem) => `<span>${elem}</span>`);

          const failedBlock = failedArr.map((elem) => `<span>${elem.split('-').reverse().join('.')}</span>`);
          const finalText = `<div class="flex column">
                            <h3>Расписание успешно создано</h3>
                            <h3>Не удалось создать брони на даты:</h3>
                            <div class="flex size-14">${failedBlock.join(',&nbsp')}</div>
                          </div>`;
          ElNotification.success({
            title: '',
            dangerouslyUseHTMLString: true,
            message: failedArr.length ? finalText : 'Расписание и брони успешно созданы',
            duration: 2000,
          });

          commit('OUTBOUND_DATA_IS_UPDATED', true);
        }
        commit('UPDATE_IS_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_IS_LOADING', false);
      });
  },

  updateOutboundItem({ commit, dispatch, state }, { id, data }) {
    commit('UPDATE_IS_LOADING', true);
    updateOutboundItem(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          const successArr = response.data.data.successDates;
          const failedArr = response.data.data.failedDates;
          const successBlock = successArr.map((elem) => `<span>${elem}</span>`);

          const failedBlock = failedArr.map((elem) => `<span>${elem}</span>`);
          const finalText = `<div class="flex column">
                            <h3>Расписание успешно создано</h3>
                            <h3>Не удалось создать брони на даты:</h3>
                            <div class="flex size-14">${failedBlock.join(',&nbsp')}</div>
                          </div>`;
          ElNotification.success({
            title: '',
            dangerouslyUseHTMLString: true,
            message: failedArr.length ? finalText : 'Расписание и брони успешно созданы',
            duration: 2000,
          });

          commit('OUTBOUND_DATA_IS_UPDATED', true);
        }
        commit('UPDATE_IS_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_IS_LOADING', false);
      });
  },

  deactivateOutboundItem({ commit, dispatch, state }, { id, data }) {
    commit('UPDATE_IS_LOADING', true);
    deactivateOutboundItem(id, data)
      .then((response) => {
        if ('success' in response.data && response.data.success) {
          ElNotification.success({
            message: response.data.message,
            duration: 2000,
          });

          commit('OUTBOUND_DATA_IS_UPDATED', true);
        }
        commit('UPDATE_IS_LOADING', false);
      })
      .catch((error) => {
        ElNotification.error({
          dangerouslyUseHTMLString: true,
          message: getErrorMessage(error.response.data),
          duration: 2000,
        });
        commit('UPDATE_IS_LOADING', false);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import { defaultApiInstance } from '@/compositions/api';

export function getGates(id) {
  return defaultApiInstance.get(`v1/storage/${id}/GateMatrix/`);
}

export function getGate(buildingId) {
  return defaultApiInstance.get(`v1/building/${buildingId}/gate`);
}

export function getGatesDirection(buildingId) {
  return defaultApiInstance.get(`v1/building/${buildingId}/direction`);
}

export function getGateStatus(buildingId) {
  return defaultApiInstance.get(`v1/building/${buildingId}/gate`);
}

export function getGateBooking(params) {
  return defaultApiInstance.get(`v1/building/${params.buildingId}/booking`, {
    params: {
      directionIds: params.directionIds,
    },
  });
}

export function gateCallTime(params) {
  return defaultApiInstance.put(`v1/booking/${params.id}/gateCallTime`, params.data);
}

export function setActivateGate(gateId) {
  return defaultApiInstance.put(`v1/gate/${gateId}/activate`);
}

export function setDeactivateGate(gateId) {
  return defaultApiInstance.put(`v1/gate/${gateId}/deactivate`);
}

export function updateGateStatus(data) {
  return defaultApiInstance.put(`v1/gate/${data.gateId}/status`, data.payload);
}
